.gth-uploads-icon {
  max-width: 40px;
  height: auto;
}
.bg-primary .btn-close {
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E")
    center/1em auto no-repeat;
}
.gth-custom-upload {
  width: 150px;
  height: 150px;
  position: relative;
}
.gth-custom-upload .gth-browose-file {
  width: 100%;
  height: 100%;
  border: 1px dashed var(--colorPrimary);
}
.gth-insur-field .input-group .btn {
  border-radius: 0 var(--sizeSmall) var(--sizeSmall) 0 !important;
}
.gth-insur-field .input-group input {
  /* border-radius: 0; */
}
.gth-upload {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.table tbody {
  border-color: var(--colorBorder) !important;
}
.gth-pro-img {
  width: 34px;
  height: 34px;
}
.gth-pro-img img {
  width: 100%;
  height: auto;
}
.gth-list-number span {
  width: 20px;
  height: 20px;
}
.gth-insur-field .gth-filled-value {
  max-width: 65%;
  width: 100%;
  padding: 0.475rem 1rem 0.675rem 1rem;
  border: 1px solid var(--colorBorder);
  border-right-width: 1;
  border-radius: 10px;
}
.gth-insur-field .input-group {
  /* max-width: 35%;
	width: 100%; */
}
.gth-attached-files .gth-pdf-modal {
  position: relative;
  top: -3px;
}
.notification:after {
  display: none;
}
.gth-notification-drop {
  width: 300px;
  max-height: 350px;
  overflow-y: auto;
}
.gth-notification-list .gth-noti-card {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.gth-noti-card h5 {
  font-size: 12px;
}
.gth-notification-date {
  opacity: 0.8;
}

@media all and (max-width: 991px) {
  .gth-address-box {
    justify-content: flex-start;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  .gth-insur-field .gth-filled-value {
    max-width: 100%;
    border-right-width: 1px;
    border-radius: var(--sizeSmall) !important;
  }
  .gth-insur-field .input-group {
    max-width: 100%;
  }
  .gth-insur-field .input-group input {
    border-radius: var(--sizeSmall) 0 0 var(--sizeSmall) !important;
  }
  .table tbody {
    border-top-width: 0 !important;
  }
  .table .gth-have-border {
    border-top-width: 2px !important;
  }
  .gth-avail-provider .gth-filter-col label {
    font-size: 14px;
  }
}
@media all and (max-width: 767px) {
  .gth-notification-drop {
    width: 280px;
  }
  .table a[href^="mailto:"] {
    word-break: break-all;
  }
}

.uploads-record-img {
  padding: 5px;
  height: auto;
  width: 100px;
}

.uploads-record-file {
  padding: 5px;
  height: auto;
  width: 50px;
}

.uploads-record-remove-img {
  position: relative;
  top: 5px;
  left: -20px;
}

#form-print-section label {
  font-weight: bold;
}

.chat-attachment-container {
  margin-right: 5px;
  margin-bottom: 15px;
}

.chat-attachment-img {
  width: 60px !important;
  height: 60px !important;
  border: 2px solid #e7eaec;
  margin-right: 1px;
}

.chat-attachment-file {
  margin-right: 20px;
  margin-bottom: 15px;
  width: 60px !important;
  height: 60px !important;
  border: 2px solid #e7eaec;
}

.chat-attachment-file-discard {
  position: relative;
  left: 25px;
  top: -21px;
}

.uploads-container {
  background-color: #f2f2f2;
  padding: 5px;
}

.message-bubble-attachment-file {
  margin-right: 5px;
  margin-bottom: 15px;
  width: 60px !important;
  height: 60px !important;
  border: 2px solid #e7eaec;
}

.chat-text-area {
  height: 35px;
  border-radius: 0px !important;
  margin: 5px;
  overflow-y: auto;
}

.modal-body .react-pdf__Page__canvas {
  max-width: 100% !important;
  height: auto !important;
}

.srch-upinner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
}

.pharmacy-list-container {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  padding: 20px 20px 5px 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 15px;
}

.pharmacy-card {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 7px;
  margin-bottom: 15px;
}

.text-align-left {
  text-align: left;
}

.second-opinion-uploads {
  width: 90px;
  min-width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: var(--sizeExtraSmall);
  transition: var(--transition);
  margin-right: 20px;
  margin-bottom: 20px;
}
