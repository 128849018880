.profile_img {
  margin-top: 0 !important;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.profile_img .circle {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid #ccc;
  min-width: 100px;
}

.profile_img .circle .bg-color {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid #ccc;
  min-width: 100px;
  background-color: #0a1d62;
}

.profile_img .circle .profile-initial {
  overflow: hidden;
  font-size: 2.5em !important;
  color: white;
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.patient-info-card .card-header img {
  width: 64px;
  height: 64px;
  min-width: 64px;
  object-fit: contain;
  background-color: transparent;
}
.accordion-button:not(.collapsed) .accordion-action-buttons .edit {
  background-color: var(--colorPrimary) !important;
  color: #fff !important;
}
/*Profile-optimization*/
#vol {
  height: 2px;
}
.crop-tab-active {
  color: var(--colorPrimary);
  font-weight: 700;
  border-bottom: 1px solid var(--colorPrimary);
}
.sec-my-dependence .family-member {
  flex: 1;
}
/*page-404*/
.section-404 .wapper h1 {
  font-weight: 700;
}
.section-404 .img-wapper {
  width: 200px;
}
.section-404 {
  min-height: calc(100vh - 71px);
}
.section-404 .wapper {
  max-width: 388px;
}
/*alert*/
.step-section .alert {
  max-width: 500px;
  position: fixed;
  width: calc(100% - 60px);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  top: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  border-radius: 0.25rem;
}
.step-section .alert-body {
  height: 70px;
}
.step-section .btn-close {
  background-size: 10px 10px;
}
.step-section .alert-msg p {
  font-size: 12px;
}

.step-section .alert-icon {
  max-width: 80px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem 0 0 0.25rem;
}
.row.smart-scan-request-wrapper{
    margin: 0 auto;
    margin-top: -1rem !important;
    max-width: 800px;
}
.row.smart-scan-request-wrapper figure img {
    height: 120px;
}
@media all and (max-width: 767px) {
  .container,
  .container-fluid {
    width: calc(100% - 30px) !important;
  }
  .upload-btn {
    font-size: 12px;
  }
  .patient-info-card .card-header img {
    width: 50px;
    height: 50px;
    min-width: 50px;
    object-fit: contain;
    background-color: transparent;
  }
  .accordion-action-btn .btn {
    background-color: var(--colorTransparent) !important;
    font-size: var(--sizeBase);
    color: var(--colorPrimary) !important;
    padding: 0px 3px;
  }
  .accordion-action-btn .btn:hover,
  .accordion-action-btn .btn:focus {
    background-color: var(--colorTransparent) !important;
  }
  .accordion-button:not(.collapsed) .accordion-action-buttons .btn {
    background-color: var(--colorTransparent) !important;
    color: var(--colorPrimary) !important;
  }

  .section-404 .img-wapper {
    width: 140px;
  }
}
@media only screen and (min-width: 992px){
  .row.smart-scan-request-wrapper .col-lg-2 {
      width: 20%;
  }
}
@media all and (max-width: 375px) {
  .sec-my-dependence .add-member {
    margin-top: 10px;
  }
  .sec-my-dependence .gth-main-header {
    display: block !important;
  }
}

@media all and (max-width: 374px) {
  .profile_img .circle {
    width: 80px;
    height: 80px;
    min-width: 80px;
  }
  .sec-my-dependence .accordion-action-buttons {
    align-items: center;
  }
  .upload-btn .btn {
    font-size: 12px;
  }
}
