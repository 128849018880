.container-fluid::before,
.container-fluid::after {
  display: none;
}

textarea {
  width: 100%;
  height: 100%;
  line-height: normal;
  resize: none !important;
  /* overflow-y: clip; */
  overflow-y: hidden;
}
#main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

#chat-wrapper {
  overflow-y: auto;
  min-height: 500px;
  max-height: calc(90vh - 295px);
}

.messages__item--typing {
  will-change: transform;
  width: auto;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 15px 30px;
  display: table;
  margin-right: auto;
  position: relative;
  animation: bulge 2s infinite ease-in-out;
  background-color: #f5f5f5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

span.messages__dot:nth-child(1) {
  background-color: #64b5f6;
  animation-delay: 0s;
}

span.messages__dot {
  height: 12px;
  width: 12px;
  float: left;
  margin: 0 4px;
  background-color: #9e9ea1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
  animation: blink 1.5s infinite, bounce 1.5s infinite;
}

#main-container .chat-msg {
  background: var(--colorSecondary);
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  display: table;
  padding: 11px 12px;
  margin-bottom: 0.5rem;
  width: auto;
  max-width: 90%;
  border-radius: 10px;
}
#main-container .chat-msg.msg-output {
  background: var(--colorSecondary);
  margin-left: var(--valueZero);
  margin-right: 0px;
}
#main-container .chat-msg.user-input {
  background-color: var(--colorPrimary);
  margin-left: auto;
}
#controller {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
}
#controller #textbox {
  width: 100%;
  font-size: 13px;
  color: var(--colorBody);
  padding: 10px 12px;
  line-height: 2;
  resize: none !important;
  text-overflow: ellipsis;
  border-color: var(--colorBorder) !important;
  border-radius: 23px;
  padding-right: 45px;
  overflow-y: hidden;
}
.chat-btn {
  width: 34px;
  min-width: 34px;
  height: 34px;
  font-size: 17px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colorPrimary);
}
.chat-btn:active,
.chat-btn:focus,
.chat-btn:hover {
  color: #fff;
}
.chat-btn.rec-start {
  background-color: #1a6988;
}

body .chat-btn.rec-start {
  /* box-shadow: 0px 0px 0px 10px rgb(26 105 136 / 0%); */
  animation: mymove 1.2s infinite;
}
@keyframes mymove {
  0% {
    box-shadow: 0px 0px 0px 0px rgb(26 105 136 / 0%);
  }
  75% {
    box-shadow: 0px 0px 0px 5px rgb(26 105 136 / 25%);
  }
  100% {
    box-shadow: 0px 0px 0px 0px rgb(26 105 136 / 0%);
  }
}

#controller #send {
  position: absolute;
  top: calc(50% - 17px);
  right: 8px;
}
.textbox-control {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.chatbox {
  width: 100%;
  max-width: 75%;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 2rem;
  word-break: break-word;
}
.chatbox .author-img {
  margin: 0px;
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 100px;
  cursor: pointer;
}
.chatbox.send-msg {
  margin-left: auto;
  margin-right: 0;
}
.chatbox .toggle #recPause {
  display: none;
}
.chatbox .toggle {
  position: relative;
}
.chatbox .toggle .toggleButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  visibility: visible;
}
.chat-btn.recorded {
  color: var(--colorPrimary);
  background-color: transparent;
}

.wellness-score-cici {
  width: 100%;
  height: 100%;
  padding: 22px 30px;
  border-radius: 20px;
  color: #ffffff;
  background: linear-gradient(to right, #54b7e8 0%, #784b96 100%);
  text-align: center;
}
.wellness-score-cici img {
  display: table;
  width: 100%;
  max-width: 135px;
  height: 100%;
  object-fit: contain;
  border-radius: 100px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  /* #controller #textbox {
    height: 78px;
  } */
  .chatbox {
    max-width: 85%;
  }
}

@media (max-width: 575px) {
  .chatbox {
    gap: 5px;
    max-width: 100%;
  }
}

img.auther-img {
  width: 40px;
  max-height: 40px;
  max-width: 40px;
  height: 40px;
}

.chat-sender-initial {
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-color: #0a1d62;
  color: white;
}
