@import url("fonts-style.css");
@import url("variable-style.css");
@import url("https://use.typekit.net/kbd6hwv.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
@import url("chat-style.css");
@import url("style-g.css");
@import url("style-p.css");
@import url("second-opinion-style.css");
@import "baguettebox.js/dist/baguetteBox.min.css";
@import url("chat.css");
/* font-family:"bootstrap-icons"; */

body,
.dashboard-main-container {
  font-family: var(--fontSofiaPro);
  background-color: var(--bodyBackground);
  font-size: var(--sizeBase);
  color: var(--colorBody);
  font-weight: var(--fontWeight400);
  text-align: left;
  line-height: var(--bodyLineHeight);
  cursor: default;
}
* {
  box-sizing: border-box;
}
.tooltip {
  max-width: 250px;
  white-space: normal;
  box-shadow: 0px 5px 30px 1px rgba(0, 0, 0, 0.25);
}
a {
  color: var(--colorPrimary);
}
a:hover {
  color: var(--colorPrimary);
  text-decoration: none;
}

a,
input,
button {
  transition: all 0.3s ease-out 0s !important;
  box-shadow: none !important;
}
img {
  display: inline-block;
  vertical-align: top;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
.transition {
  transition: all 0.3s ease-out 0s;
}

#wellness-current-value a:focus {
  background-color: #869791 !important;
}

.tab-focusable-background a:focus {
  background-color: #869791 !important;
}

.tab-focusable-background button:focus {
  background-color: #869791 !important;
}

.font-sofia {
  font-family: var(--fontSofiaPro);
}
.font-sofia-pro-soft {
  font-family: var(--fontSofiaProSoft);
}

.tk-sofia-pro-soft {
  font-family: "sofia-pro-soft", sans-serif;
}
.tk-sofia-pro {
  font-family: "sofia-pro", sans-serif;
}

.fw-normal {
  font-weight: var(--fontWeight400);
}
.fw-bold {
  font-weight: var(--fontWeight700);
}
i.fw-bold:before {
  font-weight: var(--fontWeight700) !important;
}
b,
strong {
  font-weight: var(--fontWeight700) !important;
}

.btn {
  line-height: normal;
  padding: 0.3rem 1rem 0.5rem 1rem;
  border: 1px solid transparent !important;
  border-radius: var(--roundedPill) !important;
}
.btn-lg {
  padding-top: 0.4rem;
  padding-bottom: 0.7rem;
}
.btn-sm {
  padding-top: 0.275rem;
  padding-bottom: 0.375rem;
  border-radius: 4px;
}
.btn-xs {
  font-size: 0.75rem;
  padding-top: 0.15rem;
  padding-bottom: 0.25rem;
  border-radius: 3px;
}
.btn-rounded {
  border-radius: 50px;
}

.btn-primary {
  background-color: var(--colorPrimary) !important;
  color: var(--colorWhite);
}
.btn-primary:hover {
  background-color: var(--colorPrimaryHover) !important;
  color: var(--colorWhite);
}
.btn-primary.disabled {
  background-color: var(--colorPrimary) !important;
  color: var(--colorWhite);
}
.btn-primary.disabled:hover {
  background-color: var(--colorPrimaryHover) !important;
  color: var(--colorWhite);
}
.btn-secondary {
  background-color: var(--colorSecondary) !important;
  color: var(--colorWhite);
}
.btn-secondary:hover {
  background-color: var(--colorSecondaryHover) !important;
  color: var(--colorWhite);
}
.btn-secondary.disabled {
  background-color: var(--colorSecondary) !important;
  color: var(--colorWhite);
}
.btn-secondary.disabled:hover {
  background-color: var(--colorSecondaryHover) !important;
  color: var(--colorWhite);
}
.btn-violet {
  background-color: var(--colorViolet) !important;
  color: var(--colorWhite);
}
.btn-violet:hover {
  background-color: var(--colorVioletHover) !important;
  color: var(--colorWhite);
}
.btn-gold {
  background-color: var(--colorGold) !important;
  color: var(--colorGoldDark);
}
.btn-gold:hover {
  background-color: var(--colorGoldHover) !important;
  color: var(--colorGoldDark);
}

.btn-outline {
  background-color: var(--colorTransparent) !important;
  color: var(--colorMuted);
  border-color: var(--colorMuted) !important;
}
.btn-outline:hover {
  background-color: var(--colorGoldHover) !important;
  color: var(--colorGoldDark);
  border-color: var(--colorTransparent) !important;
}
.btn-outline-primary {
  border-color: var(--colorPrimary) !important;
  color: var(--colorPrimary) !important;
}
.btn-outline-primary:hover {
  background: var(--colorPrimary) !important;
  color: var(--colorWhite) !important;
}
.btn-outline-secondary {
  border-color: var(--colorSecondary) !important;
  color: var(--colorSecondary);
}
.btn-outline-secondary:hover {
  background-color: var(--colorSecondary) !important;
  color: var(--colorWhite);
}
.btn-outline-violet {
  border-color: var(--colorViolet) !important;
  color: var(--colorViolet);
}
.btn-outline-violet:hover {
  background-color: var(--colorViolet) !important;
  color: var(--colorWhite);
}
.btn-outline-gold {
  border-color: var(--colorGold) !important;
  color: var(--colorGold);
}
.btn-outline-gold:hover {
  background-color: var(--colorGold) !important;
  color: var(--colorGoldDark);
}
.btn-outline-danger {
  border-color: var(--colorDanger) !important;
  color: var(--colorDanger);
}
.btn-outline-danger:hover {
  background-color: var(--colorDanger) !important;
  color: var(--colorWhite);
}

.primary-border-color {
  border-color: var(--colorPrimary);
}

.bg-primary {
  background-color: var(--colorPrimary) !important;
  color: var(--colorWhite);
}
.bg-secondary {
  background-color: var(--colorSecondary) !important;
  color: var(--colorWhite);
}
.bg-violet {
  background-color: var(--colorViolet) !important;
  color: var(--colorWhite);
}
.bg-gold {
  background-color: var(--colorGold) !important;
  color: var(--colorWhite);
}
.bg-body {
  background-color: var(--bodyBackground) !important;
}
.bg-gray {
  background-color: var(--colorGray) !important;
}

.text-primary {
  color: var(--colorPrimary) !important;
}
.text-secondary {
  color: var(--colorSecondary) !important;
}
.text-violet {
  color: var(--colorTextViolet) !important;
}
.text-gold {
  color: var(--colorGold) !important;
}
.text-muted {
  color: var(--colorMuted) !important;
}
.text-body {
  color: var(--colorBody) !important;
}
.text-green {
  color: var(--colorGreen) !important;
}
.space-before::before {
  content: " ";
}

h1 {
  font-size: 30px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--fontSofiaPro);
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
}

.fs-1 {
  font-size: 30px !important;
}
.fs-2 {
  font-size: 24px !important;
}
.fs-3 {
  font-size: 18px !important;
}
.fs-4 {
  font-size: 16px !important;
}
.fs-5 {
  font-size: 14px !important;
}
.fs-6 {
  font-size: 10.5px !important;
}
.fs-12 {
  font-size: 12px !important;
}

.container,
.container-fluid {
  width: calc(100% - 30px);
}

.align-content-unset {
  align-content: unset !important;
}

p {
  margin-bottom: 1rem;
}
p:last-child {
  margin-bottom: 0rem;
}
p:empty {
  display: none;
}
hr {
  background-color: #e5e5e5;
  opacity: 1;
}

::-moz-selection,
::selection {
  background-color: var(--colorPrimary);
  color: var(--colorWhite);
}

.border-radius-xlg {
  border-radius: 1.5rem;
}
.flex-1 {
  flex: 1;
}
.text-truncate {
  line-height: normal;
}

.object-contain {
  object-fit: var(--objectContain);
}
.object-cover {
  object-fit: var(--objectCover);
}

.video-responsive {
  width: 100%;
  height: 0px;
  padding-bottom: 56.25%;
  position: relative;
}
.video-responsive iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}

.breadcrumb {
  color: #888;
  margin-bottom: 0px;
}
.breadcrumb li {
  color: #595959 !important;
  margin-bottom: 5px;
}
.breadcrumb li a {
  color: #595959 !important;
  text-decoration: none !important;
}
.breadcrumb li a:hover {
  color: var(--colorPrimary) !important;
}
.breadcrumb li.active {
}
.breadcrumb li:before {
  color: #aaa !important;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  opacity: 0.5;
}
.form-check-input[type="checkbox"] {
  border-radius: 0px;
}
.form-check-input:checked {
  background-color: var(--colorPrimary);
  border-color: var(--colorPrimary);
}

.form-check {
  padding-left: 28px;
  position: relative;
}
.form-check .form-check-input {
  margin: 0px 0px;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 0px;
  top: 4px;
}

.form-check.d-flex {
}
.form-check.d-flex .form-check-input {
}

.form-check .form-check-input[type="radio"] {
}
.form-check .form-check-label {
  line-height: 1.1;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  min-height: 24px;
}
textarea {
  height: 120px;
  resize: none;
}

label {
  font-size: 16px;
  line-height: 1.1;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.divider {
  border-bottom: 1px solid var(--colorBorder);
  margin: 20px 0px;
}
[data-bs-toggle="modal"] {
  cursor: pointer;
}

.form-text {
  font-size: var(--sizeSmall);
  color: var(--colorMuted);
  line-height: 1.1;
  margin-top: 0.425rem;
}
.form-text.icon {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding-left: 30px;
  min-height: 26px;
  position: relative;
}
.form-text.icon i {
  font-size: var(--sizeExtraLarge);
  margin: 0px 0px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.form-text.icon.fs-3 i {
  top: -2px;
}

.dropdown-toggle:after {
  content: "\F282";
  font-family: "bootstrap-icons";
  font-size: 0.775rem;
  vertical-align: middle;
  border: none;
}
.dropdown-toggle-hide-arrow:after {
  content: "";
}

.accordion-button:not(.collapsed):after {
  filter: saturate(10.5);
}
.accordion-button:not(.collapsed) {
  color: var(--colorPrimary);
}
.accordion-button:after {
  background-size: 1rem 1rem;
  width: 1rem;
  height: 1rem;
}

.tooltip {
  opacity: 1 !important;
}
.tooltip .tooltip-inner {
  background-color: var(--colorWhite);
  color: var(--colorBody);
  line-height: normal;
  box-shadow: 0px 5px 30px 1px rgba(0, 0, 0, 0.25);
}
.tooltip .tooltip-arrow {
}
.tooltip.bs-tooltip-right .tooltip-arrow:before {
  border-right-color: var(--colorWhite) !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow:before {
  border-left-color: var(--colorWhite) !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: var(--colorWhite) !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: var(--colorWhite) !important;
}

/*
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before { border-right-color:#fff; }
*/
.nav-link:focus,
.nav-link:hover {
  color: inherit;
}

ul.sidebar-menu {
  list-style: none;
  padding: 0px 0px;
  margin: 0px 0px;
}
ul.sidebar-menu li {
}
ul.sidebar-menu li a {
  color: var(--colorBlack);
}
ul.sidebar-menu li a span {
}
ul.sidebar-menu li a i {
}

header {
}
header .navbar {
}
header .navbar .navbar-brand {
  width: 300px;
  height: 55px;
}
header .navbar .navbar-brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
header .navbar ul.navbar-nav {
}
header .navbar ul.navbar-nav > li {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin-left: 1.5rem;
}
header .navbar ul.navbar-nav > li:first-child {
  margin-left: 0rem;
}
header .navbar ul.navbar-nav > li > a.nav-link {
  font-size: var(--sizeMedium);
  padding: var(--valueZero);
}
header .navbar ul.dropdown-menu {
  left: auto;
  right: 0px;
}

header .navbar a.notification {
  padding: 0px 0px;
  margin-right: 0.25rem;
  margin-left: -0.25rem;
  width: 38px;
  height: 38px;
  position: relative;
}
header .navbar a.notification img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
header .navbar a.notification .num {
  background-color: #8a6f06;
  color: var(--colorWhite);
  margin-left: -0.25rem;
  width: 16px;
  height: 16px;
  z-index: 1;
}

header .navbar a.notification ~ .dropdown-menu {
  background-color: var(--colorWhite);
  color: var(--colorBody);
}
header .navbar a.notification ~ .dropdown-menu h3 {
}
header .navbar a.notification ~ .dropdown-menu .gth-notification-list {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  position: relative;
}
header .navbar a.notification ~ .dropdown-menu .gth-noti-card {
  color: inherit;
}
header .navbar a.notification ~ .dropdown-menu .gth-noti-card h4 {
  line-height: 1;
  padding-right: 4rem;
}
header .navbar a.notification ~ .dropdown-menu .gth-noti-card p {
  font-size: 13px;
  padding-right: 20px;
  margin-bottom: var(--valueZero);
}
header .navbar a.notification ~ .dropdown-menu .close {
  color: inherit;
  opacity: 0.5;
  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: 1;
}
header .navbar a.notification ~ .dropdown-menu .close:hover {
  color: inherit;
  opacity: 0.75;
}
header .navbar a.notification ~ .dropdown-menu .gth-notification-date {
  font-size: 70%;
  color: var(--colorMuted);
  font-style: italic;
  opacity: 0.75;
  position: absolute;
  top: 19px;
  right: 1rem;
}

header .navbar .user-dropdown {
}
header .navbar .user-dropdown .dropdown-menu {
  background-color: var(--colorWhite);
  color: var(--colorBody);
  padding: var(--valueZero);
  width: 190px;
}
header .navbar .user-dropdown .dropdown-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
header .navbar .user-dropdown .dropdown-menu li a {
  color: var(--colorBody) !important;
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem 1rem;
}
header .navbar .user-dropdown .dropdown-menu li a .profile-pic {
  width: 35px;
  height: 35px;
  object-fit: cover;
}
header .navbar .user-dropdown .dropdown-menu li a .profile-initial {
  width: 35px;
  height: 35px;
  object-fit: cover;
  background-color: #0a1d62;
  color: white;
}
header .navbar .user-dropdown .dropdown-menu li a .name {
  line-height: 1;
  flex: 1;
}
header .navbar .user-dropdown .dropdown-menu li a .name span {
  font-size: 12px;
  color: var(--colorMuted);
  font-style: italic;
  letter-spacing: 0.2px;
  display: block;
  margin-top: 4px;
  opacity: 0.75;
}

header .navbar ul.navbar-nav li a.user-profile {
}
header .navbar ul.navbar-nav li a.user-profile .profile-pic {
  width: 35px;
  height: 35px;
  object-fit: cover;
}
header .navbar ul.navbar-nav li a.user-profile .profile-initial {
  width: 35px;
  height: 35px;
  object-fit: cover;
  background-color: #0a1d62;
  color: white;
}
header .navbar ul.navbar-nav li a.user-profile span {
  max-width: 150px;
}
header .navbar ul.navbar-nav li a.user-profile:after {
  margin-top: 0.255rem;
}

.show-hide-div {
  display: none;
}
#show-hide-div {
  display: none;
}
.price-tag {
  height: 40px !important;
  border: 1px solid #000;
  border-radius: 100px;
  padding: 7px;
}
.wellness-score-cici {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 20px;
  border-radius: 20px;
  color: #ffffff;
  background: linear-gradient(to right, #54b7e8 0%, #784b96 100%);
}

.wellness-score-product-print {
  background: #ffffff;
  border-radius: 1rem;
  padding: 1.2rem;
  border: 1px solid #e1d9d9;
  max-width: 797px;
  margin: 30px auto;
}

.wellness-score-cici img {
  display: table;
  width: 100%;
  max-width: 130px;
  height: 100%;
  object-fit: contain;
  border-radius: 100px;
  margin: 0 auto;
}
.poor_categories .tabs_column .accordion .bi-icon {
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border-radius: 100px;
  padding: 4px 7px;
  color: var(--colorWhite);
  background-color: var(--colorSecondary) !important;
}
.poor_categories
  .tabs_column
  .accordion
  .accordion-item
  .accordion-button:not(.collapsed):after {
  display: none;
}
.poor_categories .tabs_column .accordion .accordion-item {
  padding: 1rem 1.25rem;
  border-width: 0px;
  border-radius: 1rem;
  margin-bottom: 20px;
  overflow: hidden;
}

.poor_categories .tabs_column .accordion .accordion-item .accordion-body {
  padding: 1rem;
}
.poor_categories .tabs_column .accordion .accordion-item .accordion-button {
  display: block;
  text-align: right;
  padding: 16px 15px 6px;
}
.poor_categories
  .tabs_column
  .accordion
  .accordion-item
  .accordion-button:not(.collapsed) {
  display: none;
  background-color: transparent;
}
.poor_categories .tabs_column .accordion .accordion-item .accordion-body img {
  max-width: 100px;
}
.poor_categories
  .tabs_column
  .accordion
  .accordion-item
  .accordion-body
  .qr_Code {
  max-width: 80px;
}
.dark-blend-mode {
  mix-blend-mode: darken;
}
.issues_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  list-style: none;
  padding: 0px;
  margin-bottom: 30px;
}
.issues_list li {
  display: block;
  width: 100%;
  max-width: 160px;
  text-decoration: none;
  text-align: center;
  padding: 3px 10px 5px;
  border-radius: 5px;
  line-height: normal;
  text-transform: uppercase;
}

.accordion-item-print {
  background-color: #f2f2f2 !important;
  border: 1px solid #e1d9d9 !important;
}

.member-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 18px;
  background-color: #ffffff;
  border-radius: 1rem;
  border: 1px solid #e5e5e5;
  padding: 20px;
}
.member-card img {
  height: 20px;
}
.logo-height {
  height: 20px !important;
}
.dropdown-toggle {
}
.dropdown-toggle[aria-expanded="true"] {
}
.dropdown-toggle[aria-expanded="true"]:after {
}
.dropdown-toggle[aria-expanded="false"] {
}
.dropdown-toggle[aria-expanded="false"]:after {
}

ul.dropdown-menu {
  padding: 1rem 1rem;
}
ul.dropdown-menu > li {
}
ul.dropdown-menu > li > a {
  background-color: var(--colorTransparent) !important;
  font-size: var(--sizeBase);
  color: var(--colorWhite) !important;
  padding-left: 0px;
  padding-right: 0px;
  white-space: normal;
}
ul.dropdown-menu > li > a:hover {
  text-decoration: underline;
}
ul.dropdown-menu > li > a.active {
  text-decoration: underline;
}

ul.dropdown-menu li a .badge {
  vertical-align: top;
  min-width: 17px;
  position: relative;
  top: 2px;
}
.badge.fs-6 {
  font-size: 10px !important;
}

.dropdown-menu {
  background-color: var(--colorViolet);
  color: var(--colorWhite);
  border: none;
  border-radius: 0.75rem;
  box-shadow: none;
}

.form-control {
  font-size: var(--sizeBase);
  color: var(--colorBody);
  padding: 0.475rem 1rem 0.675rem 1rem;
  border-color: var(--colorBorder) !important;
  border-radius: var(--sizeSmall);
  text-overflow: ellipsis;
  box-shadow: none !important;
}
.form-control:focus {
  border-color: var(--colorViolet) !important;
}
.form-select.form-control {
  padding-right: 1.5rem;
}
.form-control[type="date"] {
}
.form-control[type="date" i]:after {
}
.form-control[type="date" i] {
}
.form-control[type="date"] i {
}

.error {
  border-color: #f00 !important;
}
.is-invalid {
  border-color: #f00 !important;
}
.invalid-feedback {
  font-size: var(--sizeBase);
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #f00 !important;
}

::-webkit-input-placeholder {
  color: var(--colorBody) !important;
  opacity: 0.3 !important;
}
::-moz-placeholder {
  color: var(--colorBody) !important;
  opacity: 0.3 !important;
}
:-ms-input-placeholder {
  color: var(--colorBody) !important;
  opacity: 0.3 !important;
}
:-moz-placeholder {
  color: var(--colorBody) !important;
  opacity: 0.3 !important;
}

.v-middle {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  min-height: calc(100vh - 140px);
}
.progress {
  background-color: var(--colorWhite);
  height: 1.5rem;
  border-radius: var(--roundedPill);
}
.progress-bar {
  background: #00bcef;
  border-radius: var(--roundedPill);
  background: -moz-linear-gradient(left, #00bcef 0%, #983794 100%);
  background: -webkit-linear-gradient(left, #00bcef 0%, #983794 100%);
  background: linear-gradient(to right, #00bcef 0%, #983794 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00bcef', endColorstr='#983794',GradientType=1 );
}

ul#step-tab {
  z-index: 1;
}
ul#step-tab:after {
  content: "";
  border-bottom: 1px solid var(--colorMuted);
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0px;
  top: 50%;
  z-index: -1;
  transform: translateY(-50%);
}
ul#step-tab li {
}
ul#step-tab li button {
  background-color: var(--colorWhite);
  color: var(--colorMuted);
  border: 1px solid var(--colorMuted);
  width: 35px;
  height: 35px;
}
ul#step-tab li button span {
}
ul#step-tab li button .num {
  margin-top: -2px;
}
ul#step-tab li button .value {
  white-space: nowrap;
}
ul#step-tab li button .value br {
  display: none;
}

ul#step-tab li button.active {
  background-color: var(--colorPrimary);
  color: var(--colorWhite);
  border-color: var(--colorTransparent);
}
ul#step-tab li button.active .num {
}
ul#step-tab li button.active .value {
}

ul#step-tab li:nth-child(1) button.complete {
  background-color: var(--colorPrimary);
  color: var(--colorWhite);
  border-color: var(--colorTransparent);
}
ul#step-tab li:nth-child(2) button.complete {
  background-color: var(--colorSecondary);
  color: var(--colorWhite);
  border-color: var(--colorTransparent);
}
ul#step-tab li:nth-child(3) button.complete {
  background-color: var(--colorViolet);
  color: var(--colorWhite);
  border-color: var(--colorTransparent);
}
ul#step-tab li:nth-child(4) button.complete {
  background-color: var(--colorViolet);
  color: var(--colorWhite);
  border-color: var(--colorTransparent);
}

#step-tabContent {
}
#step-tabContent .btn {
  min-width: 165px;
}

.patient-member-card {
}
.patient-member-card .card-header {
  background-color: var(--colorTransparent);
}
.patient-member-card .card-header img {
  width: 75px;
  height: auto;
  object-fit: contain;
}
.patient-member-card .card-body {
}
.patient-member-card .card-footer {
  width: 40px;
  min-width: 40px;
  height: 40px;
}
.patient-member-card .card-footer i {
  margin-top: -0.225rem;
}

.upcoming-member-card {
}
.upcoming-member-card .card-body-left {
  flex: 1;
}
.upcoming-member-card .card-body-right {
}

.table tr:last-child td {
  border: 0 !important;
}

.refresh-icon {
  background-color: var(--colorWhiteHover);
  color: var(--colorPrimary);
  margin: 0.25rem 0.25rem;
  width: 1.2rem;
  height: 1.2rem;
}
.refresh-icon i {
}

.canvas-wrapper {
  width: 100%;
}
canvas.signature-pad {
  background-color: var(--colorGray);
  width: 100%;
  max-width: 100%;
  height: 130px;
}
canvas.sigCanvas {
  background-color: var(--colorGray);
  width: 100%;
  max-width: 100%;
  height: 130px;
}

.small-profile-pic {
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.range-wrapper {
  position: relative;
}
.range-wrapper .range {
  opacity: 0;
  width: 100%;
  cursor: pointer;
}

.hover-element {
  cursor: pointer;
}

.range-wrapper .range::-ms-tooltip {
  display: none;
}
.range-wrapper .track {
  background: #ddd;
  width: 100%;
  height: 3px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
}
.range-wrapper .track-inner {
  background: var(--colorPrimary);
  width: 0px;
  height: 100%;
  border-radius: 5px;
}
.range-wrapper .thumb {
  background: var(--colorSecondary);
  width: 16px;
  height: 16px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  border-radius: 50%;
}

.range-wrapper .range2 {
  opacity: 0;
  width: 100%;
  cursor: pointer;
}
.range-wrapper .range2::-ms-tooltip {
  display: none;
}
.range-wrapper .track2 {
  background: #ddd;
  width: 100%;
  height: 3px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
}
.range-wrapper .track-inner2 {
  background: var(--colorPrimary);
  width: 0px;
  height: 100%;
  border-radius: 5px;
}
.range-wrapper .thumb2 {
  background: var(--colorSecondary);
  width: 16px;
  height: 16px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  border-radius: 50%;
}

.image-optimization {
}
.image-optimization .crop-img {
  display: table;
  margin: 0 auto;
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
}
.image-optimization .crop-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.checkbox-wrapper {
}
.checkbox-wrapper .form-check {
  width: calc(20% - 1.5rem);
}

.form-section {
}
.form-section .gth-corpote-report {
  margin-bottom: 18px;
}
.form-section .gth-corpote-report:last-child {
  margin-bottom: 0px;
}
.form-section .gth-corpote-report p {
  margin-bottom: 5px;
}
.form-section .gth-corpote-report p:last-child {
  margin-bottom: 0px;
}

#refferal-invite-modal {
}
#refferal-invite-modal i {
  font-size: 60px;
  line-height: 1;
}
#refferal-invite-modal h2 {
}
#refferal-invite-modal p {
}

@media only screen and (max-width: 1199px) {
  .checkbox-wrapper .form-check {
    width: calc(25% - 1.5rem);
  }
}

@media only screen and (min-width: 992px) {
  header .navbar .dropdown .dropdown-toggle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 0.125rem !important;
    height: 100%;
  }
  header .navbar .dropdown .dropdown-toggle:after {
    margin-top: 6px !important;
  }
  header .navbar .user-dropdown .dropdown-menu li:last-child {
    border: none;
  }
}
@media only screen and (max-width: 991px) {
  header .navbar .navbar-brand {
    width: 250px;
  }

  header .navbar ul.navbar-nav > li {
    margin-left: var(--valueZero);
    margin-bottom: 2rem;
  }

  header .navbar .navbar-toggler {
  }
  header .navbar .navbar-toggler i {
  }
  header .navbar .navbar-toggler[aria-expanded="false"] {
  }
  header .navbar .navbar-toggler[aria-expanded="true"] {
  }

  header .navbar .navbar-toggler[aria-expanded="true"] ~ .navbar-collapse {
    right: 0px;
  }
  header .navbar .navbar-toggler[aria-expanded="false"] ~ .navbar-collapse {
    right: -300px;
  }

  header
    .navbar
    .navbar-toggler[aria-expanded="true"]
    ~ .navbar-collapse
    .navbar-toggler:before {
    right: 300px;
    visibility: visible;
    opacity: 1;
  }
  header
    .navbar
    .navbar-toggler[aria-expanded="false"]
    ~ .navbar-collapse
    .navbar-toggler:before {
    right: 0px;
    visibility: hidden;
    opacity: 0;
  }

  header .navbar .navbar-collapse {
    background: var(--colorWhite);
    display: block;
    padding: var(--valueZero);
    margin: var(--valueZero);
    width: 300px;
    height: 100vh;
    position: fixed;
    top: 0px;
    z-index: 9;
    transition: var(--transition);
  }
  header .navbar .navbar-collapse > * {
  }
  header .navbar .navbar-collapse .navbar-toggler {
    position: relative;
    z-index: 1;
  }
  header .navbar .navbar-collapse .navbar-toggler:before {
    content: "";
    background: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh !important;
    cursor: default;
    position: fixed;
    top: 0px;
    z-index: -1;
    transition: var(--transition);
  }
  header .navbar .navbar-collapse .inner-wrapper {
    padding: 0rem 1.25rem;
    width: 100%;
    height: calc(100vh - 60px);
    overflow-y: auto;
  }
  header .navbar .navbar-collapse .navbar-toggler ul.navbar-nav {
  }

  header .navbar .dropdown-toggle {
  }
  header .navbar .dropdown-toggle:after {
    text-align: center;
    line-height: 18px;
    margin-top: -0.125rem;
    width: 18px;
    height: 18px;
    transition: var(--transition);
  }
  header .navbar .dropdown-toggle[aria-expanded="true"] {
  }
  header .navbar .dropdown-toggle[aria-expanded="true"]:after {
    transform: rotate(0deg);
  }
  header .navbar .dropdown-toggle[aria-expanded="false"] {
  }
  header .navbar .dropdown-toggle[aria-expanded="false"]:after {
    transform: rotate(-90deg);
  }

  header .navbar ul.dropdown-menu {
    padding: 0.825rem 1.25rem;
    margin: 0.825rem -1.25rem 0rem -1.25rem;
    width: calc(100% + 2.5rem);
    border-radius: 0px;
  }

  header .navbar .user-dropdown .dropdown-menu {
    padding: 0rem 0rem;
    margin: 0.825rem -1.25rem 0rem -1.25rem;
    width: calc(100% + 2.5rem);
    border-radius: 0px;
    box-shadow: none !important;
  }
  header .navbar .user-dropdown .dropdown-menu li a {
    padding: 1rem 1.25rem;
  }

  .table tr {
    border-bottom: 1px solid var(--colorBorder);
  }
  .table tr:last-child {
    border-bottom: none;
  }
  .table tr td:first-child {
    padding-top: 1rem !important;
  }
  .table tr td:last-child {
    padding-bottom: 1rem !important;
  }
  .table td {
    display: block;
    text-align: left;
    border: 0;
    width: 100%;
    min-height: 30px;
    padding: 5px 10px 5px 150px !important;
    position: relative;
  }
  .table thead {
    display: none;
  }
  .table td:before {
    content: attr(data-label);
    font-weight: var(--fontWeight700);
    position: absolute;
    left: 10px;
  }
  .table tr:not(:last-child) td:last-child {
    /*padding-bottom:25px !important; margin-bottom:20px !important; border-bottom:1px solid #ddd;*/
  }
  .table td[data-label="Action"]:last-child {
    min-height: 46px;
  }
  .Prescription table {
  }
  .Prescription table tr td {
    min-height: 30px;
  }

  .upcoming-member-card .card-body-left .card-title span {
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }

  .notes-table tr td {
    text-align: left;
  }

  .checkbox-wrapper .form-check {
    width: calc(33% - 1.5rem);
  }
}

@media only screen and (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 12px;
  }
  h6 {
    /*font-size:10px;*/
  }

  .fs-1 {
    font-size: 26px !important;
  }
  .fs-2 {
    font-size: 20px !important;
  }
  .fs-3 {
    font-size: 16px !important;
  }
  .fs-4 {
    font-size: 14px !important;
  }
  .fs-5 {
    font-size: 12px !important;
  }
  .fs-6 {
    /*font-size:10px !important;*/
  }

  .btn {
    font-size: 0.925rem;
  }
  .btn-lg {
    font-size: 1.05rem;
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
  }
  .btn-sm {
    font-size: 0.825rem;
  }
  .btn-xs {
    font-size: 0.75rem;
  }

  .v-middle {
    min-height: calc(100vh - 96px);
  }
  .border-radius-xlg {
    border-radius: 1rem;
  }
  .form-control {
    padding-top: var(--sizeExtraSmall);
    padding-bottom: var(--sizeExtraSmall);
    border-radius: var(--sizeExtraSmall);
  }
  .progress {
    height: 1rem;
  }

  label {
    font-size: 14px;
  }

  header .navbar .navbar-brand {
    width: 165px;
  }
  header .navbar a.notification {
    width: 32px;
    height: 32px;
  }

  header .navbar a.notification ~ .dropdown-menu {
    max-width: 268px;
    right: -45px;
  }

  ul#step-tab li button {
    width: 30px;
    height: 30px;
  }
  ul#step-tab li button .value br {
    display: block;
  }
  #step-tabContent .btn {
    width: 100%;
    min-width: inherit;
  }

  .patient-member-card .card-header img {
    margin-left: -1rem;
    width: 60px;
    min-width: 60px;
  }
  .patient-member-card .card-footer {
    margin-right: -2rem;
    width: 30px;
    min-width: 30px;
    height: 30px;
  }

  .upcoming-member-card .card-body-left {
  }
  .upcoming-member-card .card-body-left .card-title {
    margin-bottom: 0.25rem;
  }
  .upcoming-member-card .card-body-left .card-title span {
    width: auto;
  }
  .upcoming-member-card .card-body-left .card-text {
    margin-bottom: 0.5rem;
  }
  .upcoming-member-card .card-body-right {
    flex-direction: row !important;
    width: 100%;
  }
  .upcoming-member-card .card-body-right .appointment-buttons .btn {
    padding-left: 10px;
    padding-right: 10px;
  }

  .image-optimization .modal-footer a {
    font-size: 0.75rem;
  }
  .image-optimization .modal-footer .btn {
    font-size: 0.75rem;
    padding-top: 0.15rem;
    padding-bottom: 0.25rem;
    border-radius: 3px;
  }

  .notes-table tr td {
    padding-left: 90px !important;
  }
  .checkbox-wrapper .form-check {
    width: calc(50% - 1.5rem);
  }

  .table {
    font-size: 12px !important;
  }
  .table td {
    text-align: left;
    padding-left: 10px !important;
    min-height: 26px;
  }
  .table td:before {
    display: table;
    margin-top: 10px;
    margin-bottom: 3px;
    width: 100%;
    position: static;
  }
  .table tr td:first-child:before {
    margin-top: 0px;
  }
  .table .fs-4 {
    font-size: inherit !important;
  }
  .table h4 {
    font-size: inherit !important;
  }
  .table .gth-pro-detail {
    flex-wrap: wrap;
    justify-content: flex-start !important;
  }
  .table .gth-pro-detail .gth-pro-img {
    margin-right: 0rem !important;
    margin-bottom: 0.5rem !important;
  }
  .table .gth-pro-detail .gth-pro-meta {
    width: 100%;
  }
  .table .gth-address-box {
    padding-left: 0px;
    margin-left: 0px;
  }
  .form-section .gth-panel-header .gth-btns-group {
    margin-top: 0.5rem;
    text-align: left;
    width: 100%;
  }

  .form-modal-body {
    padding: 0 !important;
  }

  .campaign-form-container {
    padding: 5px !important;
  }

  .form-modal-body .card-body {
    padding: 0.5rem !important;
    line-height: 2;
  }

  .field-content {
    margin: 1rem;
  }

  .form-border-radius {
    padding: 0 !important;
  }

  .form-cancel-btn {
    margin-bottom: 1.5rem;
  }

  #accordionSmartScan .accordion-item {
    border-radius: 1rem;
  }
  #accordionSmartScan .accordion-item .accordion-header .condition {
    min-width: 155px;
  }
  .smart-scan-wrapper figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .smart-scan-instruction-wrapper figure {
    height: calc(100vh - 315px);
    min-height: 180px;
  }

  .smart-scan-selfie-wrapper figure {
    height: calc(100vh - 290px);
    min-height: 180px;
  }
}

@media only screen and (max-width: 479px) {
  .upcoming-member-card .card-body-left .join-video-call-wrapper {
    margin-left: 0px;
    width: 100%;
  }
  .upcoming-member-card .card-body-left .join-video-call-wrapper .btn {
    /*padding-left:1.5rem; padding-right:1.5rem;*/
  }
  .upcoming-member-card .card-body-right {
    flex-direction: column-reverse !important;
  }
  .upcoming-member-card .card-body-right .appointment-buttons {
    flex: 1;
    margin-bottom: var(--valueZero) !important;
  }
  .upcoming-member-card .card-body-right .appointment-icons {
    border-bottom: 1px solid var(--colorGray);
    padding: 12px 0px;
    margin-bottom: 4px;
  }
  .checkbox-wrapper .form-check {
    width: calc(100% - 1.5rem);
  }
  #score-chart {
    max-width: 100%;
  }
  .highcharts-figure {
    margin-left: -50px;
  }

  .campaign-form-container {
    text-align: left;
  }

  .campaign-form-container .formio-component-submit {
    text-align: center;
  }

  .campaign-form-container
    .formio-component-areYouExperiencingAnyOfTheFollowingCheckOffAllThatApply
    div {
    display: grid;
    grid-template-columns: auto auto;
  }

  .campaign-form-container
    .formio-component-areYouExperiencingAnyOfTheFollowingCheckOffAllThatApply
    div
    > div {
    margin-top: 5px;
  }

  .form-check .form-check-label {
    display: inline-block;
  }

  .campaign-form-container .formio-component-doYouHaveAnyAllergies div {
    display: grid !important;
  }

  .campaign-form-container
    .formio-component-areYouCurrentlyTakingAnyMedications
    div {
    display: grid !important;
  }

  .campaign-form-container .formio-component-doYouHaveAnyMedicalConditions div {
    display: grid !important;
  }

  .campaign-form-container .field-content {
    margin: 0px 0px 5px !important;
  }
  .campaign-form-container .field-content div {
    grid-template-columns: auto auto;
  }
}

@media only screen and (max-width: 390px) {
  .campaign-form-container
    .formio-component-areYouExperiencingAnyOfTheFollowingCheckOffAllThatApply
    div {
    display: grid;
    grid-template-columns: auto;
  }
}

@media only screen and (max-width: 374px) {
  .appointment-icons a:last-child {
    margin-right: 0rem !important;
  }
  .appointment-icons a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .referral-history-table tr td a.btn.text-nowrap {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    white-space: normal !important;
  }
}

.chat-attachment-uploads i {
  width: 100%;
  height: auto;
}

#campaign-form .btn {
  margin-top: 10px;
}

.campaign-form-container {
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 20px;
  margin-top: 10px;
}

.schedule-appointment-slot .item {
  margin-right: 10px;
  border-radius: 5px;
  border-width: 0px !important;
  margin-left: 10px;
}

.schedule-appointment-slot .item:hover {
  background: var(--colorPrimary);
  border: none;
  color: #fff;
}

.schedule-appointment-slot .selected {
  background: var(--colorPrimary);
  border: none;
  color: #fff;
}

.provider-profile-img {
  max-height: 170px;
}

.gth-notification-date {
  top: -12px !important;
}

.bg-green {
  background-color: var(--colorGreen) !important;
}
.bg-green-light {
  background-color: var(--colorGreenLight) !important;
}
.rounded-t-l-0 {
  border-top-left-radius: 0px !important;
}
.rounded-t-r-0 {
  border-top-right-radius: 0px !important;
}
.rounded-b-l-0 {
  border-bottom-left-radius: 0px !important;
}
.rounded-b-r-0 {
  border-bottom-right-radius: 0px !important;
}
.rounded-t-l-1 {
  border-top-left-radius: 1.5rem !important;
}

.rounded-t-r-1 {
  border-top-right-radius: 1.5rem !important;
}

.rounded-b-l-1 {
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-b-r-1 {
  border-bottom-right-radius: 1.5rem !important;
}
#accordionSmartScan .accordion-item {
  border: none;
  border-left: 1.5rem solid var(--colorBorder);
  border-radius: 1.5rem;
}
#accordionSmartScan .accordion-item .accordion-header {
  cursor: pointer;
}
#accordionSmartScan .accordion-item .accordion-header:before {
  content: "\F282";
  font-family: var(--fontBootstrapIcon);
  font-size: var(--sizeLarge);
  color: var(--colorBorder);
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 10px;
  top: 26px;
  transform: rotate(0deg);
  transition: var(--transition);
}
#accordionSmartScan
  .accordion-item
  .accordion-header[aria-expanded="true"]:before {
  transform: rotate(-180deg);
}
#accordionSmartScan .accordion-item.accordion-success {
  border-color: var(--colorGreen);
}
#accordionSmartScan .accordion-item.accordion-success .color-inherit {
  color: var(--colorGreen);
}
#accordionSmartScan .accordion-item.accordion-gold {
  border-color: var(--colorGold);
}
#accordionSmartScan .accordion-item.accordion-gold .color-inherit {
  color: var(--colorGold);
}
#accordionSmartScan .accordion-item.accordion-danger {
  border-color: var(--colorDanger);
}
#accordionSmartScan .accordion-item.accordion-danger .color-inherit {
  color: var(--colorDanger);
}
#accordionSmartScan .accordion-item .accordion-header .name {
  flex: 1;
  padding-right: 15px;
}
#accordionSmartScan .accordion-item .accordion-header .score-condition {
  display: flex;
  flex-wrap: wrap;
  text-align: right;
}
#accordionSmartScan .accordion-item .accordion-header .condition {
  min-width: 180px;
}
.smart-scan-card .score-text {
  font-size: 11px;
}
.highcharts-figure * {
  font-family: var(--fontSofiaPro);
}

.highcharts-figure .chart-bottom-text {
  font-size: 11px !important;
  color: var(--colorBody) !important;
  fill: var(--colorBody) !important;
  font-weight: var(--fontWeight700);
  text-align: center;
  margin: 0 auto;
  max-width: 300px;
}

#score-chart {
  margin: 0 auto;
  max-width: 350px;
}
#score-chart .highcharts-yaxis-labels:after {
  content: "";
}
#score-chart .highcharts-yaxis-labels * {
  font-size: 11px !important;
  color: #aaa !important;
  fill: #aaa !important;
}
#score-chart .highcharts-xaxis-labels * {
  font-size: 9px !important;
  color: var(--colorBody) !important;
  fill: var(--colorBody) !important;
  font-weight: var(--fontWeight700);
  line-height: 1;
}
#score-chart .highcharts-xaxis-labels text {
  text-align: center;
}
#score-chart .highcharts-xaxis-labels text tspan {
  font-weight: var(--fontWeight400);
}
#score-chart .highcharts-container {
  margin-bottom: -45px;
  width: 100% !important;
  height: auto !important;
}
#score-chart .highcharts-root {
  font-size: 11px !important;
  width: 100%;
  height: auto;
}
#score-chart .highcharts-no-tooltip,
#score-chart .highcharts-credits {
  display: none !important;
}
#score-chart .highcharts-markers > path {
  fill: transparent;
  stroke: var(--colorViolet);
  stroke-width: 2.5;
  cursor: pointer;
}
#score-chart .highcharts-markers > path path {
  stroke-width: 0;
}
#score-chart .highcharts-data-labels text {
  font-size: 9px !important;
  color: var(--colorViolet) !important;
  fill: var(--colorViolet) !important;
}
#score-chart .highcharts-area {
  fill: var(--colorPrimary);
  opacity: 0.75;
}
#score-chart .highcharts-subtitle {
  color: var(--colorBody) !important;
  fill: var(--colorBody) !important;
  font-weight: var(--fontWeight700);
}

#score-chart .highcharts-root image {
  background: #ddd;
  width: 30px;
  height: 30px;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.3;
}

.disclamer-text {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.tooltip-title {
  display: none;
  position: absolute;
  top: -36px;
  left: 50%;
  font-size: 14px;
  background-color: #fff;
  color: var(--colorMuted);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1;
  transform: translateX(-50%);
}

@media (max-width: 374px) {
  .accordion .accordion-item .product-title-row {
    flex: auto;
  }
}

@media (max-width: 425px) {
  .poor_categories .tabs_column {
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
  }
}

@media only screen and (max-width: 575px) {
  #accordionSmartScan .accordion-item {
    border-width: 1rem;
  }
  #accordionSmartScan .accordion-item .accordion-header {
    flex-wrap: nowrap !important;
    padding-left: 2.2rem !important;
  }
  #accordionSmartScan .accordion-item .accordion-header:before {
    font-size: 16px;
    width: 18px;
    height: 18px;
  }
  #accordionSmartScan .accordion-item .accordion-header.fs-3 {
    font-size: 14px !important;
  }
  #accordionSmartScan .accordion-item .accordion-header .name {
    padding-right: 10px;
  }
  #accordionSmartScan .accordion-item .accordion-header .score {
    margin-right: 5px !important;
  }
  #accordionSmartScan .accordion-item .accordion-header .condition {
    width: 90px;
    min-width: inherit;
  }
  .smart-scan-instruction-wrapper figure {
    height: calc(100vh - 335px);
  }
  .smart-scan-disclaimer.android-device .disclamer-text {
    height: calc(100vh - 320px);
  }
  .smart-scan-disclaimer.iphone-device .disclamer-text {
    height: calc(100vh - 320px);
  }

  .smart-scan-instruction-wrapper.android-device figure {
    height: calc(100vh - 335px);
  }
  .smart-scan-instruction-wrapper.iphone-device figure {
    height: calc(100vh - 400px);
  }

  .smart-scan-selfie-wrapper.android-device figure {
    height: calc(100vh - 370px);
  }
  .smart-scan-selfie-wrapper.iphone-device figure {
    height: calc(100vh - 380px);
  }

  .smart-scan-bottom-lip.android-device figure {
    height: calc(100vh - 340px);
  }
  .smart-scan-bottom-lip.iphone-device figure {
    height: calc(100vh - 355px);
  }

  .smart-scan-upper-arch.android-device figure {
    height: calc(100vh - 370px);
  }
  .smart-scan-upper-arch.iphone-device figure {
    height: calc(100vh - 380px);
  }

  .smart-scan-upper-lip.android-device figure {
    height: calc(100vh - 370px);
  }
  .smart-scan-upper-lip.iphone-device figure {
    height: calc(100vh - 380px);
  }

  .smart-scan-mouth-close.android-device figure {
    height: calc(100vh - 340px);
  }
  .smart-scan-mouth-close.iphone-device figure {
    height: calc(100vh - 355px);
  }

  .smart-scan-bite.android-device figure {
    height: calc(100vh - 370px);
  }
  .smart-scan-bite.iphone-device figure {
    height: calc(100vh - 376px);
  }
  .smart-scan-instruction-wrapper .content-wrapper {
    min-height: 235px;
  }
  .smart-scan-selfie-wrapper figure {
    height: calc(100vh - 310px);
  }
  .smart-scan-selfie-wrapper .content-wrapper {
    min-height: 180px;
  }
  .poor_categories .tabs_column .accordion .accordion-item {
    padding: 0.6rem;
  }
  .poor_categories .tabs_column .accordion .accordion-item .accordion-header {
    padding-left: 0px !important;
  }
  .poor_categories .tabs_column .accordion .accordion-item h3,
  .poor_categories .tabs_column .accordion .accordion-item button {
    font-size: 14px;
  }
  .poor_categories .tabs_column .accordion .accordion-item p {
    font-size: 12px;
  }
  .issues_list {
    gap: 12px;
  }
  .issues_list li {
    max-width: 130px;
    font-size: 11px;
  }
}

.rpe-item {
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #e7eaec;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.rpe-item .rpe-reports {
  width: 100%;
  display: inline-block;
  padding-top: 5px;
}

.n-education-content {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
}

.n-education-content label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

.rpe-item .rpe-reports .rpe-img {
  margin-right: 10px;
  width: 70px;
  height: auto;
  float: left;
  border: 2px solid #e7eaec;
  border-radius: 5px;
  overflow: hidden;
}

.rpe-item .rpe-reports img {
  width: 100%;
  height: 100%;
}

.rpe-img {
  width: 55px !important;
  height: 55px !important;
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}

.show-password > a {
  text-decoration: none;
}

.show-password > a:hover {
  text-decoration: underline;
}

.form-section
  .gth-panel-header
  > *[aria-expanded="true"]
  ~ .gth-btns-group
  a.view-form {
  display: none;
}
.form-section
  .gth-panel-header
  > *[aria-expanded="false"]
  ~ .gth-btns-group
  a.hide-form {
  display: none;
}
.form-section .gth-panel-header .gth-btns-group a {
  font-size: var(--sizeBase);
  margin-bottom: 0.5rem;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .form-section .gth-panel-header .gth-btns-group a {
    font-size: 0.75rem;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.alert-dialog-overlay {
  background-color: #ffffffd4;
  z-index: 600000;
  padding: 20px;
  display: block !important;
}

.react-confirm-alert {
  width: auto !important;
  font-family: Arial, Helvetica, sans-serif;
  padding: 30px;
  margin: 15px auto !important;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
}

.react-confirm-alert h1 {
  margin-bottom: 15px;
}

.react-confirm-alert-button-group {
  justify-content: flex-end;
}

.alert-dialog-yes-button,
.alert-dialog-ok-button {
  background-color: var(--colorPrimary) !important;
}

.alert-dialog-no-button {
  background-color: var(--colorDanger) !important;
}

.alert-dialog-ok-button:hover,
.alert-dialog-yes-button:hover {
  background-color: var(--colorPrimaryHover) !important;
}

.alert-dialog-no-button:hover {
  background-color: #bb2d3b !important;
}

.react-confirm-alert-button-group > button {
  outline: none;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: rgb(255, 255, 255);
  border-radius: var(--roundedPill) !important;
  font-size: 12px;
  cursor: pointer;
  margin-left: 10px !important;
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .react-confirm-alert {
    max-width: 450px !important;
  }
}

.opacity-1 {
  opacity: 1 !important;
}

#goog-gt-tt, .skiptranslate .skiptranslate {
    display: none !important;
}

@media (max-width: 768px) {
  .btn-skip-alignment {
  text-align: center !important;
}
}

.no-border {
  border: none;
}

.btn-skip-alignment {
  text-align: right;
}

.VIpgJd-ZVi9od-xl07Ob-OEVmcd{
  box-shadow: none !important;
}

.btn-skip {
  text-decoration: none;
  color: #888888;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none !important;
}

.cicichattranslate {
  text-align: left !important;
}

@media (max-width: 991px) {
  .note-video-clip {
    width: 100% !important;
    height: 100% !important;
  }
}

.visit-history-profile-initial {
  width: 34px;
  height: 34px;
  background-color: #0a1d62;
  color: white;
}

.goog-te-menu-frame {
  overflow-x: auto;
  box-sizing: border-box !important;
}

@media (max-width: 991px) {
  .goog-te-menu-frame {
    max-width: 150px !important;
  }
}

.clear-notification {
  padding: 15px;
  background: rgb(255, 255, 255);
  z-index: 2;
  font-size: 13px;
  border-radius: 0 0 0.75rem 0.75rem;
}

.clear-notification span {
  color: var(--colorPrimary);
  cursor: pointer;
}

.clear-notification span:hover {
  text-decoration: underline;
}

.clear-notification img {
  max-width: 25px;
}

.pagination-bar {
  flex-wrap: wrap;
}

.help-block {
  color: red;
}

.react-select__control {
  font-size: var(--sizeBase);
  color: var(--colorBody);
  border-color: var(--colorBorder) !important;
  border-radius: var(--sizeSmall) !important;
  text-overflow: ellipsis;
  box-shadow: none !important;
  height: 41px !important;
}

.react-select__control.react-select__control--is-focused {
  border-color: var(--colorViolet) !important;
}

.react-select__indicator-separator {
  width: 0 !important;
}

.react-select__placeholder {
  padding-left: 0.4rem !important;
  color: #ccc !important;
}

@media only screen and (max-width: 991px) {
  #accordionSmartScan .accordion-item {
    border: none;
    border-left: 1.5rem solid var(--colorBorder);
  }
  #accordionSmartScan .accordion-item .accordion-header {
    cursor: pointer;
  }
  #accordionSmartScan .accordion-item .accordion-header:before {
    content: "\F282";
    font-family: var(--fontBootstrapIcon);
    font-size: var(--sizeLarge);
    color: var(--colorBorder);
    line-height: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 10px;
    top: 26px;
    transform: rotate(0deg);
    transition: var(--transition);
  }
  #accordionSmartScan
    .accordion-item
    .accordion-header[aria-expanded="true"]:before {
    transform: rotate(-180deg);
  }
  #accordionSmartScan .accordion-item.accordion-success {
    border-color: var(--colorGreen);
  }
  #accordionSmartScan .accordion-item.accordion-success .color-inherit {
    color: var(--colorGreen);
  }
  #accordionSmartScan .accordion-item.accordion-gold {
    border-color: var(--colorGold);
  }
  #accordionSmartScan .accordion-item.accordion-gold .color-inherit {
    color: var(--colorGold);
  }
  #accordionSmartScan .accordion-item.accordion-danger {
    border-color: var(--colorDanger);
  }
  #accordionSmartScan .accordion-item.accordion-danger .color-inherit {
    color: var(--colorDanger);
  }
  #accordionSmartScan .accordion-item .accordion-header .name {
    flex: 1;
    padding-right: 15px;
  }
  #accordionSmartScan .accordion-item .accordion-header .score-condition {
    display: flex;
    flex-wrap: wrap;
    text-align: right;
  }
  #accordionSmartScan .accordion-item .accordion-header .condition {
    min-width: 180px;
  }
}

#accordionSmartScan .accordion-item.accordion-non-observable {
  border-color: var(--colorMuted);
}

#accordionSmartScan .accordion-item.accordion-non-observable .color-inherit {
  color: var(--colorMuted);
}

@media only screen and (max-width: 575px) {
  #accordionSmartScan .accordion-item {
    border-width: 1rem;
  }
  #accordionSmartScan .accordion-item .accordion-header {
    flex-wrap: nowrap !important;
    padding-left: 2.2rem !important;
  }
  #accordionSmartScan .accordion-item .accordion-header:before {
    font-size: 16px;
    width: 18px;
    height: 18px;
  }
  #accordionSmartScan .accordion-item .accordion-header.fs-3 {
    font-size: 14px !important;
  }
  #accordionSmartScan .accordion-item .accordion-header .name {
    padding-right: 10px;
  }
  #accordionSmartScan .accordion-item .accordion-header .score {
    margin-right: 5px !important;
  }
  #accordionSmartScan .accordion-item .accordion-header .condition {
    width: 90px;
    min-width: inherit;
  }

  .smart-scan-instruction-wrapper figure {
    height: calc(100vh - 335px);
  }
  .smart-scan-instruction-wrapper .content-wrapper {
    min-height: 235px;
  }

  .smart-scan-selfie-wrapper figure {
    height: calc(100vh - 310px);
  }
  .smart-scan-selfie-wrapper .content-wrapper {
    min-height: 180px;
  }
}

@media only screen and (max-width: 767px) {
  #accordionSmartScan .accordion-item {
    border-radius: 1rem;
  }
}

.filter-grayscale {
  filter: grayscale(1) !important;
}

.wellness-score-print {
  color: black !important;
}

.wellness-score-print small {
  font-size: 0.875em;
}

.wellness-score-print .fs-5 {
  font-size: 1.25rem !important;
}

.dental-condition-score {
  padding: 0 !important;
  font-size: calc(1.275rem + 0.3vw) !important;
  margin-bottom: 0.5rem !important;
}

.wellness-score-summary {
  all: unset;
}

.wellness-score-summary td {
  display: table-cell !important;
  padding: 0 !important;
}

.dental-condition {
  margin-bottom: 30px !important;
}
.tab-button {
  margin-right: 15px;
  font-size: 20px;
  background: transparent;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 0.2s;
  border-bottom: 2px solid transparent;
  width: 100%;
}

.tab-active {
  background: white;
  color: black;
}

.text-danger {
  color: var(--colorDanger) !important;
}

.section-brand img {
  width: 300px;
  height: 55px;
}

.signature-pad-body img[src=""],
.signature-pad-body img:not([src]) {
  display: none !important;
}

.instruction-sample-txt {
  font-size: 3rem;
  z-index: 1;
  transform: translate(-50%, -50%) rotateZ(333deg);
}

.smartscan-ref-img {
  width: 220px;
  height: 220px;
  top: 0px;
  right: 0px;
  z-index: 2;
  transition: var(--transition);
}
.smartscan-ref-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.smartscan-ref-img.expand {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 767.98px) {
  .instruction-sample-txt {
    font-size: 2.5rem;
  }

  .smartscan-ref-img {
    width: 120px;
    height: 120px;
  }
}

.smartscan-refrence-img-wrp img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.smart-scan-fixed-button-bottom {
  padding-bottom: 105px;
  min-height: calc(100vh - 180px - 3rem * 2);
  position: relative;
  max-height: -webkit-fill-available;
  display: flex;
}

.smart-scan-fixed-button-bottom .btn-div {
  position: absolute;
  left: 50%;
  bottom: 0px;
  z-index: 1;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
}
.smartscan-tab-button {
  margin-bottom: -1px;
}
.smartscan-tab-button:first-child {
  border-top-left-radius: 1rem;
}

.smartscan-tab-button:last-child {
  border-top-right-radius: 1rem;
}
.whiteness_num {
  font-size: 44px;
  padding: 0px 14px 10px;
  border-radius: 12px;
}

.shade {
  width: 100%;
  max-width: 175px;
  bottom: 0px;
  left: 50%;
  text-transform: uppercase;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 600;
  padding: 8px 5px;
  text-align: center;
  border-radius: 0px 0px 4px 4px;
  transform: translateX(7%);
}

.shade-grid {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

.shade-item {
  width: 90px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.shade-item:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.shade-item.active {
  border: 3px solid #007bff;
}

.selected-shade {
  margin-top: 20px;
  text-align: center;
}

.shade-display {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
}

.copy-confirm-footer {
  display: block;
  opacity: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 6px 10px;
  transition: opacity 0.5s;
}

.copy-confirm-footer.active {
  opacity: 1;
}

.attached-file {
  display: inline-block;
  height: 101px;
  width: 101px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  margin: 4px;
}

.attached-file-img {
  width: 100%;
  object-fit: cover;
  object-position: top center;
}

.title-entr {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4px;
  background-color: #e9f2f9;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.atc-otr-view {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

.btn-secondary.continue {
  min-width: 270px;
}

@media only screen and (max-width: 374px) {
  .btn-secondary.continue {
    min-width: 100%;
  }
}

.treatment-list {
  list-style: none;
  font-family: Arial, Helvetica, sans-serif;
}
.treatment-list li {
  margin-bottom: 15px;
}
.treatment-list li label {
  font-size: 14px;
  font-weight: 600;
}

.user-address-dropdown .dropdown-menu {
  background-color: #fff;
  width: 347px;
  padding: 0px;
  border: 1px;
  border-color: #eee;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.address-suggestion-list .ant-list-item:hover {
  background-color: #eee;
}

.address-suggestion-list {
  padding: 8px;
}

.address-suggestion-list .ant-list-item {
  margin: 0px;
  padding: 4px;
  flex-direction: row;
}

.place-icon {
  color: gray;
}

/* card modal */
#Subscriber-modal .modal-content {
  position: relative;
  border: none;
  border-radius: 15px;
  overflow: hidden;
  padding-left: 45px;
}
#Subscriber-modal .modal-content::before {
  content: "myCigna.com";
  position: absolute;
  top: 50%;
  left: 0;
  width: 45px;
  height: calc(100% + 10px);
  font-size: 28px;
  color: #ffffff;
  text-align: center;
  writing-mode: vertical-lr;
  padding: 0px 8px;
  background-color: #016cf8;
  transform: rotate(180deg) translateY(50%);
}
#Subscriber-modal .modal-content .modal-body {
  background-color: #ffffff;
}
#Subscriber-modal .modal-content .modal-body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../images/cignahlthcr_logo.svg");
  background-size: 380px;
  background-repeat: no-repeat;
  background-position: center calc(50% - 20px);
  opacity: 0.04;
  pointer-events: none;
}
#Subscriber-modal .column {
  margin-bottom: 14px;
}
#Subscriber-modal ul.card-list {
  width: 100%;
  max-width: 260px;
  list-style: none;
  padding-left: 0;
  margin-bottom: 6px;
}
#Subscriber-modal ul.card-list li {
  display: flex;
  justify-content: space-between;
  column-gap: 3px;
  margin-bottom: 0px;
}
#Subscriber-modal p {
  font-weight: 300;
  margin-bottom: 0;
}
#Subscriber-modal .card-logo {
  width: 100%;
  max-width: 180px;
  margin-bottom: 5px;
}
#Subscriber-modal .title {
  font-weight: 600;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: 8px;
}
#Subscriber-modal .icon {
  width: 100%;
  max-width: 22px;
}
#Subscriber-modal .contact {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  #Subscriber-modal .modal-content {
    padding-left: 32px;
  }
  #Subscriber-modal .modal-content::before {
    width: 32px;
    font-size: 18px;
    padding: 0px 7px;
  }
  #Subscriber-modal .modal-content .modal-body {
    padding-left: 8px;
    padding-right: 8px;
  }
  #Subscriber-modal .modal-content .modal-body h4,
  #Subscriber-modal .modal-content .modal-body p,
  #Subscriber-modal .modal-content .modal-body li {
    font-size: 9px !important;
  }
  #Subscriber-modal .modal-content .modal-body p.fs-4 {
    font-size: 10px !important;
  }
  #Subscriber-modal .modal-content .modal-body p svg {
    width: 16px;
    height: 16px;
    margin-top: -3px;
  }
  #Subscriber-modal .modal-content .modal-body h3 {
    font-size: 16px;
  }
  #Subscriber-modal
    .modal-content
    .modal-body
    .row
    > [class*="col-"]:nth-child(1) {
    padding-right: 10px;
  }
  #Subscriber-modal
    .modal-content
    .modal-body
    .row
    > [class*="col-"]:nth-child(2) {
    padding-left: 0;
  }
  #Subscriber-modal ul.card-list {
    max-width: 100%;
  }

  #score-number .owl-item {
    padding-top: 1px;
    padding-bottom: 5px;
  }
  .grid-score-wrapper .user-score {
    padding-left: 0px;
  }
  #score-number {
    max-width: calc(100%);
  }
  .user-score {
    max-width: 80px;
  }
  #score-number .owl-nav .owl-prev {
    left: -108px;
  }
  #score-number .item .box {
    height: 68px;
  }
  .user-score .box {
    width: 74px;
    height: 74px;
  }
  .user-score .box span {
    font-size: 12px;
  }
  #score-number .owl-stage {
    height: 95px !important;
  }
}

@media only screen and (max-width: 374px) {
  #Subscriber-modal .modal-content {
    padding-left: 30px;
  }
  #Subscriber-modal .modal-content::before {
    width: 30px;
    font-size: 16px;
    padding: 0px 6px;
  }

  #Subscriber-modal .modal-content .modal-body img {
    max-width: 75px !important;
  }
}

@media only screen and (max-width: 319px) {
  #Subscriber-modal .modal-content {
    padding-left: 26px;
  }
  #Subscriber-modal .modal-content::before {
    width: 26px;
    font-size: 13px;
    padding: 0px 4px;
  }
  #Subscriber-modal .modal-content .modal-body h4,
  #Subscriber-modal .modal-content .modal-body p,
  #Subscriber-modal .modal-content .modal-body li {
    font-size: 10px !important;
  }
  #Subscriber-modal .modal-content .modal-body h3 {
    font-size: 12px;
  }
}

/*WhitnessScore Page*/
.grid-score-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}
#score-number {
  width: 100%;
  position: relative;
}
#score-number .owl-item {
  display: flex;
  justify-content: space-evenly;
}
.user-score,
#score-number .item {
  width: 100%;
  max-width: calc(100% - 20px);
  /* max-width: calc(100% - 26%); */
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid transparent;
  margin: 0px 0px;
  transition: all 0.4s ease-in-out;
}
.user-score {
  /* width: 32%; */
  max-width: 110px;
}
.user-score .box,
#score-number .item .box {
  position: relative;
  width: 100%;
  height: 78px;
  padding: 10px;
  color: #fff;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: var(--bg-color);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px -1px;
}
.user-score .box {
  width: 100%;
  height: 90px;
}
.user-score .box span,
#score-number .item .box span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #000;
  transition: all 0.4s ease-in-out;
}
#score-number .current .item {
  border-color: var(--colorPrimary);
}
#score-number .owl-nav .owl-prev,
#score-number .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  left: -12px;
  transform: translateY(calc(-50% - 8px));
  width: 36px;
  height: 36px;
  border: none;
  background-color: transparent;
  border-radius: 100px;
}
#score-number .owl-nav .owl-next {
  left: auto;
  right: -12px;
}
#score-number .owl-nav .owl-prev span,
#score-number .owl-nav .owl-next span {
  font-size: 36px;
  line-height: 0;
}
#score-number .owl-nav .owl-prev.disabled span,
#score-number .owl-nav .owl-next.disabled span {
  color: #9b9999;
  cursor: no-drop;
}

.owl-theme .owl-nav [class*="owl-"] {
  transition: all 0.3s ease;
}

.owl-theme .owl-nav [class*="owl-"].disabled:hover {
  background-color: #d6d6d6;
}

#score-image.owl-theme {
  position: relative;
}

#score-image.owl-item {
  opacity: 0;
  transition: opacity 3s ease-in-out;
  -webkit-transition: opacity 3s ease-in-out;
  -moz-transition: opacity 3s ease-in-out;
  -ms-transition: opacity 3s ease-in-out;
  -o-transition: opacity 3s ease-in-out;
}
#score-image.owl-item.active {
  opacity: 1;
}

.broken-column,
#score-image .item {
  position: relative;
  padding-bottom: 36px;
}
#score-image .item .num {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 24px;
  border-radius: 4px;
  background: var(--bg-color);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px -1px;
}
.broken-column .shade,
#score-image .item .shade {
  width: 100%;
  max-width: 175px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  text-transform: uppercase;
  font-size: 14px;
  /* color: #ffffff; */
  white-space: nowrap;
  font-weight: 600;
  padding: 8px 5px;
  text-align: center;
  border-radius: 0px 0px 4px 4px;
  /* background-color: #e5c923; */
  background-color: var(--bg-color);
  transform: translateX(-50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px -1px;
}
#score-image .item img {
  height: 250px;
}

.tooth-comparision > [class*="col-"] {
  padding: 0px 1px;
}
.tooth-comparision > * {
  border: none !important;
}
.tooth-comparision .broken-column {
  position: relative;
}
.tooth-comparision .broken-column .num-column {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 8px 24px;
  border-radius: 4px;
  background-color: #ebe3b5;
}
.tooth-comparision .broken-column .text {
  position: absolute;
  left: 10px;
  bottom: 36px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
}
#score-number .owl-stage {
  height: 100px !important;
  overflow: hidden !important;
}

@media only screen and (max-width: 767px) {
  .smart-scan-card.mobile-center .content-center {
    margin-left: calc(-30px - 15px);
    width: calc(100% + 20px + 25px);
  }
}

.margin-left-0 {
  margin-left: 0px !important;
}
.custom-border-radius-xlg {
  border-radius: 0rem 1.5rem 1.5rem 1.5rem;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #000000 !important;
  text-decoration: none;
}
/*END WhitnessScore Page*/

.smart-scan-images-div {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.smart-scan-images {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 767px) {
  .smart-scan-submit-btn-div {
    padding-top: 10px;
  }
}

.otp-form {
  max-width: 500px;
  margin: 0 auto;
}

.otp-input {
  width: auto;
  height: 2.5rem;
  font-size: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin-right: 0.3rem;
}

@media (max-width: 576px) {
  .otp-input {
    width: auto;
    height: 2rem;
    font-size: 1.25rem;
    margin-right: 0.2rem;
  }
}

@media (min-width: 768px) {
  .otp-input {
    width: auto;
    height: 3rem;
    margin-right: 0.4rem;
  }
}
