.appointment-wrapper {
}

.appointment-wrapper .left-wrapper {
  padding: 30px 30px;
  border-radius: 1.5rem 0 0 1.5rem;
}
.appointment-wrapper .left-wrapper .show-pack-detail {
}
.appointment-wrapper .left-wrapper .show-pack-detail h3 {
  font-size: 20px;
}
.appointment-wrapper .left-wrapper .show-pack-detail .ammont-view {
  font-size: 20px;
}

.appointment-wrapper .right-wrapper {
  padding: 0px 0px 0px 30px;
}
.select-slot {
}
.select-slot .slot-calendar2 {
}
.select-slot .pick-date {
  position: relative;
}
.select-slot .pick-date .owl-stage-outer {
}
.select-slot .pick-date .owl-stage-outer .owl-stage {
  display: flex;
  flex-wrap: wrap;
}

.select-slot .pick-date .owl-nav {
}
.select-slot .pick-date .owl-nav button {
  border: none !important;
}
.select-slot .pick-date .owl-nav .owl-prev {
}
.select-slot .pick-date .owl-nav .owl-next {
}

.select-slot .pick-date .owl-dots {
}

.mobile-pack {
}
.mobile-pack li {
}
.mobile-pack li a {
  color: var(--colorPrimary);
  text-decoration: none;
  line-height: 1.2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: 1px solid var(--colorPrimary);
  border-radius: var(--sizeExtraSmall);
}
.mobile-pack li a.show-pack-detail {
  font-size: 16px;
  justify-content: flex-start;
  flex: 1;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
}
.mobile-pack li a.clickpopbtn {
  font-size: 12px;
  color: var(--colorWhite);
  padding: var(--sizeSmall);
}

#ui-datepicker-div {
  display: none;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="number"],
textarea {
  -webkit-appearance: none;
}
.header-logo a:focus {
  opacity: 1;
}
.ms-choice {
  line-height: 21px;
  height: 21px;
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
.ms-choice > div {
  /* background: url(../../images/down-arrow.svg) no-repeat; */
  height: 100% !important;
  background-position: left 14px !important;
}
.calender_confirm_list label {
  font-size: 14px;
}
.ms-choice > span {
  padding-left: 0;
}
.show-pack-detail.emergency-appointment-click::before,
.show-pack-detail.emergency-appointment-click-mob::before {
  content: "";
  height: 40px;
  width: 40px;
  /* background: url(../../images/danger.svg)no-repeat; */
  position: absolute;
  left: 15px;
}
.show-pack-detail {
  position: relative;
}
.show-pack-detail:hover img.no_hover,
.show-pack-detail.active img.no_hover {
  display: none;
}
.show-pack-detail img.on_hover,
.show-pack-detail.active img.on_hover {
  display: none;
  width: 15px;
}
.show-pack-detail:hover img.on_hover,
.show-pack-detail.active img.on_hover {
  display: block;
}
.show-pack-detail img {
  position: absolute;
  right: 16px;
  bottom: 14px;
}
/*mobile package start*/
.mobile-pack li {
  list-style: none;
  display: block;
  width: 100%;
  position: relative;
}
.show-pack-detail.emergency-appointment-click,
.show-pack-detail.emergency-appointment-click-mob {
  padding-left: 62px !important;
}
.mobile-pack li .show-pack-detail .ammont-view {
  font-size: 24px;
  line-height: 24px;
  margin-top: 12px;
  position: relative;
}
.mobile-pack li .show-pack-detail .ammont-view small {
  font-size: 16px;
}
.mobile-pack li .show-pack-detail:focus,
.mobile-pack li .show-pack-detail:hover,
.mobile-pack li .show-pack-detail.select-package-mobile {
  background: var(--colorPrimary);
  color: #fff;
  border-color: var(--colorPrimary);
  opacity: 1;
}
.clickpopbtn {
  background: var(--colorPrimary);
  color: #fff;
  font-size: 13px;
  text-align: center;
  border-radius: 8px;
  padding: 25px 7px;
  display: flex;
  align-items: center;
}
/*mobile package start end*/
/* -- Form style -- */
.offcanvas-left {
  float: left;
  position: relative;
  padding: 30px 40px;
}
.offcanvas-right {
  float: left;
  padding: 40px 40px 32px 40px;
  background: #fff;
  min-height: 100%;
  position: relative;
}
.offcanvas-right.side_with_head {
  padding: 0;
}
.offcanvas-right.side_with_head .side_head {
  padding: 20px 40px 20px;
  border-bottom: 2px solid var(--colorPrimary);
}
.w-100 {
  width: 100%;
}
.offcanvas-right.side_with_head .side_body {
  padding: 0px 40px 40px;
}
.offcanvas-packages-list {
  width: 100%;
  float: left;
  opacity: 1;
  visibility: visible;
  transition: var(--transition);
}
.offcanvas-packages-detail {
  width: 100%;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
}
.show-packdetail {
  opacity: 1;
  visibility: visible;
}
.hide-pack-list {
  opacity: 0;
  visibility: hidden;
}
.has-offcanvas {
  overflow: hidden !important;
}
.paymentcancel_appointment_sidebar,
.cancel_appointment_sidebar,
.schedule_video_call_sidebar,
.offcanvas,
.offcanvas_appintment_confirm {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  float: left;
  width: 100%;
  max-width: 1015px;
  height: 100%;
  z-index: 99999999;
  background-color: #fff;
  padding: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
  -o-transition: 0.7s transform cubic-bezier(0.25, 1, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
  overflow-y: scroll;
}
.paymentcancel_appointment_sidebar.open-slide,
.cancel_appointment_sidebar.open-slide,
.schedule_video_call_sidebar.open-slide,
.has-offcanvas .offcanvas,
.has-offcanvas .offcanvas_appintment_confirm {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  background: var(--colorPrimary);
}
.cancel_appointment_sidebar {
  max-width: 688px;
}
.cancel_appointment_sidebar .offcanvas_confirm_left {
  display: none;
}
.cancel_appointment_sidebar .side_confirmed {
  width: 100%;
}
.sechudle_video_call {
  width: 100%;
}
.schedule_video_call_sidebar {
  max-width: 592px;
}
a.get_directions.promo_video_mobile img {
  position: relative;
  top: -2px;
}
.offcanvas-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: block;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
}
.offcanvas-overlay.active,
.has-offcanvas .offcanvas-overlay {
  opacity: 1;
  pointer-events: auto;
}
.close-sidemenu {
  position: fixed;
  top: 20px;
  right: 35px;
  width: 18px;
  height: 18px;
  /* background: url(../../images/close.svg) no-repeat; */
  z-index: 99;
}
.offcanvas ul {
  display: block;
  margin-bottom: auto;
  opacity: 0;
  -webkit-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
  -webkit-transition: opacity 0.45s cubic-bezier(0.2, 0.75, 0.3, 1) 0.22s,
    -webkit-transform 0.45s cubic-bezier(0.2, 0.75, 0.3, 1) 0.22s;
  transition: opacity 0.45s cubic-bezier(0.2, 0.75, 0.3, 1) 0.22s,
    -webkit-transform 0.45s cubic-bezier(0.2, 0.75, 0.3, 1) 0.22s;
  -o-transition: transform 0.45s cubic-bezier(0.2, 0.75, 0.3, 1) 0.22s,
    opacity 0.45s cubic-bezier(0.2, 0.75, 0.3, 1) 0.22s;
  transition: transform 0.45s cubic-bezier(0.2, 0.75, 0.3, 1) 0.22s,
    opacity 0.45s cubic-bezier(0.2, 0.75, 0.3, 1) 0.22s;
  transition: transform 0.45s cubic-bezier(0.2, 0.75, 0.3, 1) 0.22s,
    opacity 0.45s cubic-bezier(0.2, 0.75, 0.3, 1) 0.22s,
    -webkit-transform 0.45s cubic-bezier(0.2, 0.75, 0.3, 1) 0.22s;
}
.has-offcanvas ul {
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.offcanvas-packages-list li {
  list-style: none;
  float: left;
  display: block;
  width: 100%;
  margin: 10px 0;
}
.offcanvas-packages-list ul {
  float: left;
  width: 100%;
  margin: 15px 0;
}
.offcanvas-packages-list li .show-pack-detail {
  border: 1px solid #fff;
  display: block;
  padding: 15px 15px;
  color: #fff;
  z-index: 2;
  border-radius: 4px;
}
.offcanvas-packages-list li .show-pack-detail:hover .ammont-view:after {
  /* background: url('../../images/arrow__.svg') no-repeat; */
}
.offcanvas-packages-list li .show-pack-detail .ammont-view {
  position: relative;
}
.offcanvas-packages-list li .show-pack-detail .ammont-view small {
  font-size: 70%;
}
.offcanvas-packages-detail {
  padding: 30px 30px !important;
}
.back-pack-detail {
  color: #fff;
  font-size: 15px;
}
.vertical_flex {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back_btn-right {
  position: absolute;
  left: 40px;
  top: 10px;
  color: var(--colorPrimary);
  z-index: 99;
}
.back_btn-right:before {
  content: "";
  /* background: url(../../images/left-arrow-black.svg); */
  width: 11px;
  height: 19px;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -7px;
}
.price-details small {
  font-size: 24px;
  margin-left: 5px;
}
.price-details {
  font-size: 28px;
  text-align: left;
  display: block;
}
h6.text-blue {
  font-weight: 600;
}
.text-blue {
  color: var(--colorPrimary);
}
.row-col {
  margin: 0 -8px;
  float: left;
  width: calc(100% + 16px);
}
.form-group,
.campaign-form-sec .form-group,
.camapaign-forms .form-group {
  /* margin-bottom: 25px; */
}
.full-field {
  float: left;
  width: 100%;
  margin-top: 25px;
  position: relative;
}
.half-field {
  width: 50%;
  float: left;
  padding: 0 8px;
}
.campaign-form-sec .form-control,
.camapaign-forms .form-control,
.campaign-form-sec .panel-title {
  font-size: 16px;
}
.campaign-form-sec .form-control:active,
.campaign-form-sec .form-control:focus,
.camapaign-forms .form-control:active,
.camapaign-forms .form-control:focus,
.form-field:active,
.form-field:focus {
  border-color: #2858a7;
}
.slot-box {
  width: calc(25% - 10px);
  float: left;
  height: 50px;
  border: 1px solid #ccc;
  padding: 12px;
  text-align: center;
  font-size: 16px;
  margin: 5px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.slot-box-outer {
  margin: 0 -5px;
}
.slot-box span {
  font-size: 9px;
  display: block;
  margin-top: 2px;
}
.slot-box p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.booked-slot:hover,
.booked-slot {
  background: #ddd !important;
  cursor: no-drop !important;
  box-shadow: none !important;
  border: none !important;
  color: var(--colorBody) !important;
}
.select-slot h4 {
  font-size: 15px;
  margin-bottom: 5px !important;
  float: left;
  width: 100%;
}
.slot-box:hover {
  background: #e9f2f9;
  border: 1px solid #b2d1e5;
  color: #27507e;
}
.confirm-booking {
  text-align: center;
  float: left;
  width: 100%;
}
.select-slot {
  float: left;
  width: 100%;
  position: relative;
}
.price-view {
  display: inline-block;
  font-size: 28px;
  text-align: center;
}
/* -- /Form style -- */
.mobile-pack li .show-pack-detail .ammont-view {
  font-size: 24px;
  line-height: 24px;
  margin-top: 10px;
  position: relative;
}
.mobile-pack li .show-pack-detail:hover:before {
  content: "";
  /* background-image: url(images/background-logo.svg) !important; */
  width: 100px;
  height: 100px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: right bottom;
  right: 0;
  z-index: 999999;
  background-size: 80px;
  transform: rotate(-34deg);
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 104;
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
  padding: 30px 20px;
}
.has-popup .popup-overlay {
  opacity: 1;
  pointer-events: auto;
}
.popup-main {
  position: fixed;
  float: left;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 999999999;
  background-color: var(--colorPrimary);
  padding: 30px 30px;
  display: none;
  min-width: 100%;
  border-radius: 0;
  top: 0;
  overflow: scroll;
  max-height: 100vh;
  min-height: 100%;
  color: var(--colorWhite);
}
.popup-main h2 {
  text-align: left;
  color: #fff !important;
  padding: 0;
  margin: 0 0 10px;
}
.popup-main h3 {
  text-align: left;
  color: #fff !important;
  padding: 0;
  margin: 0 0 10px;
}
.popup-main .price-details {
  color: #fff;
  text-align: left;
}
.popup-main .pack-img {
  margin-bottom: 20px;
}
.popup-main .pack-img img {
  max-width: 100%;
}
.close-popup1,
.close-popup2,
.close-popup3,
.close-popup4,
.close_click_sidemenu_appointment_confirmed {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 10px;
  color: var(--colorWhite) !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.close-popup1 i,
.close-popup2 i,
.close-popup3 i,
.close-popup4 i,
.close_click_sidemenu_appointment_confirmed i {
  font-size: 24px;
}
.popup-main p {
  color: #fff;
  margin-bottom: 15px;
}
.popup-main .pack-img {
  border-radius: 8px;
}
.for_tab {
  display: none !important;
}
.explore_location_mobile1 {
  display: none;
}

/*confirm_details start*/
.offcanvas-confirm-details h4 {
  font-size: 21px;
  line-height: 25px;
  color: #fff;
  margin-bottom: 25px;
}
.required_star {
  color: red;
}
.emer-form {
  background-color: #fff;
}
.emer-form h2 {
  color: #000;
}
.emer-form .close-popup4 {
  /* background: url(../../images/close.svg) no-repeat; */
  background-size: 16px;
}
#ui-datepicker-div {
  background: #fff;
  box-shadow: 0px 0px 10px #ccc;
  width: 100%;
  margin-right: 15px !important;
  padding: 20px 10px;
  max-width: 320px;
  left: unset !important;
  right: 42px;
  border: 1px solid #ccc;
  margin-top: 17px;
}
.ui-datepicker-calendar {
  width: 100%;
  text-align: center;
}
.ui-datepicker-calendar td,
.ui-datepicker-calendar th {
  text-align: center;
}
.ui-datepicker-prev {
  left: 25px;
  position: absolute;
}
.ui-datepicker-next {
  position: absolute;
  right: 25px;
}
.ui-datepicker-header {
  text-align: center;
  padding: 0 15px 15px 15px;
}
.ui-datepicker-prev:before {
  content: "\f104";
  font-family: fontawesome;
  font-size: 25px;
}
.ui-datepicker-next:after {
  content: "\f105";
  font-family: fontawesome;
  font-size: 25px;
}
.ui-datepicker-prev span,
.ui-datepicker-next span {
  display: none;
}
.ui-datepicker-title {
  color: #000;
  font-weight: bold;
}
.ui-datepicker-today a:hover,
.ui-datepicker-today a {
  color: #fff !important;
  background: var(--colorPrimary) !important;
  border-radius: 100%;
}
.ui-datepicker-calendar tbody td .ui-state-default {
  padding: 5px;
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  color: #000;
  line-height: 26px;
}
.ui-datepicker-calendar tbody td .ui-state-default:hover {
  background: #ccc;
}
.ui-datepicker-calendar thead th {
  font-size: 14px;
  padding: 5px 0 10px;
}
.slot-calendar {
  position: relative;
}
#datepicker_label {
  position: absolute;
  bottom: 5px;
  right: 15px;
}
div#ui-datepicker-div::before {
  content: "";
  height: 15px;
  width: 15px;
  background-color: #fff;
  position: absolute;
  top: -7px;
  box-shadow: -2px -3px 3px #ccc;
  right: 12px;
  transform: rotate(45deg);
  display: none;
}
#ui-datepicker-div .ui-datepicker-month,
#ui-datepicker-div .ui-datepicker-year {
  border: none;
  background-image: none !important;
}
#ui-datepicker-div .ui-datepicker-month:focus,
#ui-datepicker-div .ui-datepicker-year:focus {
  outline: none;
}
.confirm_details_right {
  position: relative;
}
.success_confirmed {
  position: absolute;
  bottom: 9%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding: 40px 20px;
  min-width: 383px;
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.33);
  display: none;
}
.success_confirmed h2 {
  font-size: 28px;
  margin-top: 25px;
}
.block {
  display: block;
}
.offcanvas-packages-list li .show-pack-detail:hover,
.offcanvas-packages-list li .show-pack-detail.active {
  background-color: #fff;
  color: var(--colorPrimary);
  opacity: 1;
}
.dental_package_video {
  margin-top: 35px;
}
.select-slot .owl-carousel {
  display: inline-block !important;
  max-width: 100%;
  padding: 0 60px;
  border: 1px solid #ccc;
  border-radius: 6px;
  overflow: hidden;
  z-index: unset;
}
.select-slot .item {
  text-align: center;
  border-right: 1px solid #ccc;
  padding: 9px 0 3px;
  cursor: pointer;
}
.select-slot .owl-nav button.owl-prev {
  border-right: 1px solid #ccc !important;
}
.select-slot .owl-item .holiday span,
.select-slot .owl-item .holiday h4 {
  color: #777 !important;
}
.select-slot .owl-item .open span,
.select-slot .owl-item .open h4 {
  color: #27507e !important;
}
.select-slot .owl-item .open {
  background: #e9f2f9;
  border-radius: 0px;
}
.select-slot .owl-item .item.holiday {
  background: #f2f2f2;
  border-radius: 0px;
}
.select-slot .item h4 {
  margin: 0 !important;
  line-height: 17px;
  font-size: 16px;
}
.select-slot .item span {
  font-size: 11px;
  color: #000;
}
.select-slot .owl-nav button {
  position: absolute;
  top: 0;
  left: 0;
  width: 65px;
  background: inherit !important;
  height: 100%;
  font-size: 13px !important;
  color: var(--colorPrimary) !important;
  text-align: center;
  outline: none !important;
}
.select-slot .owl-nav button.owl-next {
  left: unset;
  right: 0;
  text-align: center;
}
.select-date-error {
  padding: 0 10px 0;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: #000;
}
.select-date-error h3 {
  font-size: 28px;
  font-weight: 500;
}
.select-date > h4 {
  display: inline-block;
  width: unset;
}
.slot-calendar {
  float: right;
  margin-top: 32px;
  color: #000;
  cursor: pointer;
  z-index: 1;
}
.slot-calendar input {
  background: transparent;
  border: none !important;
  line-height: unset !important;
  height: unset !important;
  vertical-align: baseline;
  padding-left: 32px;
  max-width: 120px;
  color: var(--colorPrimary);
}
.slot-calendar .fa-calendar {
  z-index: -1;
  margin-right: -30px;
}
.slot-calendar span {
  color: var(--colorPrimary);
  font-size: 14px;
  margin-left: 5px;
}
/*multiselect*/
.ms-choice > span {
  position: static;
}
.ms-choice,
.ms-choice:focus {
  border: none;
  outline: none;
}
.ms-drop input[type="radio"],
.ms-drop input[type="checkbox"] {
  display: none;
}
.ms-drop {
  left: 0;
}
.sechudle_video_call .subtitle_ {
  font-size: 20px;
}
.sechudle_video_call.confirm_ .btn {
  padding: 12px 30px;
}
.update_reminder .notification_ {
  color: #e2574c;
  font-size: 14px;
}
.update_reminder {
  width: 100%;
  float: left;
  padding: 0 25px;
}
.update_reminder .content_ {
  font-size: 13px;
}
/*multiselect*/
#datepicker2,
.datepicker {
  border: none;
  width: 85px;
  color: var(--colorPrimary);
}
#datepicker2:focus,
.datepicker:focus {
  outline: none;
}
.slot-calendar2 {
  right: 0;
  position: absolute;
  top: 0;
}
.date_slider {
  margin-top: 5px;
}
.explore_location h3 {
  font-size: 20px;
  font-weight: normal;
}
.provider_details_inner {
  margin-top: 15%;
  text-align: center;
}
.provider_details_inner img {
  border: 7px solid #fff;
  border-radius: 2px;
}
.provider_details_inner h2 {
  margin-top: 30px;
  color: #fff;
  font-size: 29px;
  line-height: 35px;
}
.provider_details_inner h3 {
  margin-top: 5px;
  color: #fff;
  font-size: 22px;
  line-height: 26px;
}
.learn_provider,
#explore_location {
  margin-top: 5px;
  display: inline-block;
  cursor: pointer;
  color: var(--colorPrimary);
  font-weight: 600;
}
.provider_details_inner h4 {
  margin-top: 5px;
  color: #fff;
  font-size: 19px;
  line-height: 23px;
}
.provider_details_inner h5 {
  margin-top: 5px;
  color: #fff;
  font-size: 17px;
  line-height: 20px;
}
.offcanvas_confirm {
  background: #fff !important;
  max-width: 680px;
  padding: 25px 50px;
}

.appointments {
  background: #e9f2f9;
  padding: 20px 25px;
  border-radius: 6px;
  border: 1px solid #e9f2f9;
  display: inline-block;
  width: 100%;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
.confirm-left {
  width: 50%;
  padding: 0px 15px;
}
.add_medical_history {
  padding: 12px 10px;
  background-color: var(--colorPrimary);
  color: #fff;
  display: block;
  border-radius: 4px;
  text-align: center;
  font-size: 13px;
  margin-top: 30px;
}
.confirm-right {
  width: 50%;
  padding: 0px 15px;
}
.add_to_calender {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 150px;
}
.appointments .confirm-list label {
  font-weight: 600;
  margin-bottom: 5px;
}
.appointments .confirm-list {
  margin-bottom: 18px;
}
.appointment-title img {
  margin-bottom: 15px;
}
.pic_and_name {
  display: flex;
  align-items: center;
}
.provider_location {
  display: flex;
  align-items: center;
}

.get_directions {
  font-size: 13px;
  margin-top: 10px;
  display: inline-block;
  color: var(--colorPrimary);
}
.provider_address a {
  color: var(--colorPrimary);
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.provider_location img {
  max-width: 95px;
  margin-right: 10px;
}
.pic_and_name img {
  width: 45px;
  min-width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
}
.appointment-title {
  font-size: 24px;
  color: var(--colorPrimary);
  text-align: center;
  margin-bottom: 5px;
  margin-top: 20px;
}
.appointment-info {
  font-size: 16px;
  color: #000;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
}
.appointments .confirm-list label img {
  max-width: 30px;
  margin-right: 8px;
}
.confirm-list-inner {
  width: 50%;
  float: left;
}
.confirm-list.confirm-list-new {
  display: inline-block;
  width: 100%;
}
.confirm-list-inner label {
  color: var(--colorPrimary) !important;
  font-size: 13px;
}
.confirm-list-inner label .fa {
  color: #000;
  margin-right: 5px;
  vertical-align: unset;
}
.appointments_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.appointments_buttons .btn {
  min-width: 190px;
  padding: 6px 20px 6px;
  text-align: center;
  font-size: 18px;
  border-radius: 3px;
  margin: 0 10px;
  display: inline-block;
  font-weight: bold;
  line-height: 30px;
}
.save_check_in {
  text-align: center;
  font-size: 20px;
  margin-top: 35px;
  margin-bottom: 10px;
}
.cancellation_fee p {
  font-size: 20px;
  margin-top: 15px;
  line-height: 22px;
  color: #fff;
}
.cancellation_fee {
  margin-top: 150px;
}
.cancellation_fee h2 {
  margin-top: 15px;
  color: #fff;
  font-size: 25px;
}
.appointments_buttons .btn.button_dark {
  background-color: var(--colorPrimary);
  color: #fff;
  border: 1px solid var(--colorPrimary);
}
.appointments_buttons .btn.button_light {
  background-color: transparent;
  color: #1c1e20;
  border: 1px solid #1c1e20;
}
.confirm_details_slide {
  max-width: 673px;
}
.confirm_details_slide .confirm_details_right {
  width: 100%;
  min-height: 100%;
}
.explore_location_mobile1 .provider_details_inner {
  margin-top: 40px;
}

/*sidebar css*/
.slot-box.active {
  background: var(--colorPrimary);
  border: none;
  color: #fff;
}
.promo_video {
  margin-left: 15px;
}
.promo_video img {
  margin-right: 5px;
  height: 11px;
}
.offcanvas_confirm.video__ {
  max-width: 1080px;
  padding: 0;
  background-color: #000 !important;
}
.offcanvas_confirm.video__ .side_confirmed {
  width: calc(100% - 400px);
  float: left;
  padding: 25px 50px;
  background: #fff;
  min-height: 100%;
}
.offcanvas_confirm.video__ .offcanvas_confirm_left {
  display: block !important;
  min-height: 100%;
}
.offcanvas_confirm.video__ .offcanvas_confirm_left .explore_location {
  display: block;
}
.side_confirmed {
  background-color: #fff;
}
/*sidebar css*/
.slot-box.active {
  background: #e9f2f9;
  border: 1px solid #b2d1e5;
  color: #27507e;
}
.promo_video {
  margin-left: 15px;
}
.promo_video img {
  margin-right: 5px;
  height: 11px;
}
.offcanvas_confirm.video__ {
  max-width: 1080px;
  padding: 0;
  background-color: #000 !important;
}
.offcanvas_confirm.video__ .side_confirmed {
  width: calc(100% - 400px);
  float: left;
  padding: 25px 50px;
  background: #fff;
  min-height: 100%;
}
.offcanvas_confirm.video__ .offcanvas_confirm_left {
  display: block !important;
  min-height: 100%;
}
.offcanvas_confirm.video_reschedule {
  max-width: 1080px;
  padding: 0;
  background-color: #000 !important;
}
.offcanvas_confirm.video_reschedule .side_confirmed {
  width: calc(100% - 400px);
  float: left;
  padding: 25px 50px;
  background: #fff;
  min-height: 100%;
}
.offcanvas_confirm.video_reschedule .offcanvas_confirm_left {
  display: block !important;
  min-height: 100%;
}
.side_confirmed {
  background-color: #fff;
}
.appointment_data {
  display: flex !important;
  padding-top: 40px;
  padding-bottom: 30px !important;
  border-bottom: 1px solid #ccc;
}
.appointment_data li {
  min-width: 93px;
  padding: 0 25px;
  position: relative;
}
.appointment_data li:first-child {
  padding-left: 0;
}
.appointment_data li::after {
  content: "";
  height: 53px;
  width: 1px;
  background-color: #cccccc;
  position: absolute;
  right: 0;
  top: 0;
}
.appointment_data li:last-child::after {
  display: none;
}
.p_title {
  font-size: 15px;
}
.p_content {
  font-size: 15px;
}
.pay-ammount {
  color: #fff;
  font-size: 18px;
}
.order-info {
  margin-top: 20px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 5px;
  margin-bottom: 45px;

  font-weight: 400;
}
.customer-info {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 15px;
}
.schedule-info {
  margin-bottom: 50px;
  display: inline-block;
  width: 100%;

  font-weight: 400;
}
.order-total.ammount-info {
  border-top: 1px solid #eeeeee;
  padding-top: 15px;
}

.ammount-info {
  font-size: 16px;
  display: inline-block;
  width: 100%;

  font-weight: 400;
}
.total-ammount {
  border-top: 1px dashed #eeeeee;
  padding-top: 20px;
  margin-top: 20px;

  font-size: 25px;
}
.schedule-info span:first-child {
  float: left;
}
.payment-modal .offcanvas-right h3 {
  color: #000;
  margin-top: 25px;
}
.payment-method {
  margin-top: 15%;
}
.payment-method .aj_priv_box.active {
  border: 1px solid #24c765;
}
.payment-method .aj_priv_box {
  position: relative;
  max-width: 250px;
  margin: 0 auto;
  border: 1px solid #ccc;
}
.payment-method .aj_priv_box > img {
  width: 100%;
  filter: grayscale(100%);
}
.payment-method .aj_priv_box.active > img {
  filter: unset;
}
.payment-method .aj_priv_box.active span.aj_hover_tick {
  display: block;
}

.payment-method .aj_hover_tick {
  position: absolute;
  top: -15px;
  right: -8px;
  z-index: 99;
  display: none;
}
.payment-method .or-option {
  max-width: 250px;
  margin: 30px auto;
  text-align: center;
  position: relative;
  z-index: 1;
}
.payment-method .aj_priv_box {
  position: relative;
  max-width: 250px;
  margin: 0 auto;
  border: 1px solid #ccc;
}
.total-ammount span:last-child,
.schedule-info span:last-child,
.order-info span:last-child {
  float: right;
}
.payment-modal h3 {
  font-size: 32px;
  font-weight: bold;
  padding: 15px 0 10px;

  color: #fff;
}
.payment-method .or-option span {
  background: #fff;
  padding: 0 10px;
}
.payment-method .or-option:after {
  content: "";
  border-bottom: 1px solid #ccc;
  height: 1px;
  display: inline-block;
  width: 100%;
  position: absolute;
  left: 0;
  top: 13px;
  z-index: -1;
}
.payment-method .aj_priv_box > img {
  width: 100%;
  filter: grayscale(100%);
}
.payment-method .aj_hover_tick {
  position: absolute;
  top: -15px;
  right: -8px;
  z-index: 99;
  display: none;
}
.card-detail {
  border: 1px solid #555;
  padding: 0 20px;
  border-radius: 10px;
  display: inline-block;
  margin-top: 50px;
  width: 100%;
  position: relative;
}
.visacard {
  position: absolute;
  right: 25px;
  top: 40px;
}
.card-detail .form-fields {
  margin: 20px 0;
}
.form-view label.fa::before {
  font-family: "FontAwesome";
}
.card-detail .form-fields input {
  color: var(--colorPrimary) !important;
  font-weight: bold;
  font-size: 16px;
}
.card-detail .form-fields input {
  padding: 0 !important;
  height: unset;
  line-height: unset;
  border: none;
  font-size: 16px !important;
  display: inline-block;
  max-width: 200px;
}
.confirm-booking {
  text-align: center;
  float: left;
  width: 100%;
}
.cencelled_confirmed {
  margin-top: 180px;
}
.pay-here .pay-sidemenu {
  width: 100%;
  margin-top: 40px;
}
.form-fields-row .form-fields {
  width: 33.3333%;
  float: left;
}
a.back-pack-detail:hover {
  color: #fff;
}
.for_mobile {
  display: none;
}
.card-detail .form-fields input::placeholder {
  color: var(--colorPrimary);
}
.card-detail .form-fields input.cvv::placeholder {
  color: #fff !important;
}
.card-detail .form-fields input.cvv {
  max-width: 94px !important;
  height: 33px !important;
  color: #fff !important;
  padding: 5px !important;
  background: var(--colorPrimary);
  color: #fff !important;
}
@media only screen and (max-width: 991px) {
  .service_info h4 {
    font-size: 16px;
  }
  .offcanvas_confirm {
    background: #fff !important;
    max-width: 534px;
    padding: 25px 15px;
  }
  .offcanvas_confirm.video__ .offcanvas_confirm_left .explore_location {
    display: block;
  }
  .offcanvas_confirm.video__ .side_confirmed {
    width: calc(100% - 300px);
    float: left;
    padding: 25px 15px;
    background: #fff;
    min-height: 100%;
  }
  .offcanvas_confirm.video__ .offcanvas_confirm_left {
    display: block !important;
    min-height: 100%;
    width: 300px;
  }
  .provider_details_inner {
    margin-top: 5%;
  }
  .visit-col-left .heading {
    font-size: 28px;
  }
  .visit-col-left {
    padding: 30px;
  }
  .for_desktop_large {
    display: none !important;
  }
  .for_tab {
    display: inline-block !important;
  }
  .offcanvas-right {
    padding: 30px 15px;
  }
  .offcanvas-right.sechudle_video_call {
    width: 100%;
  }
  .offcanvas-left {
    padding: 30px 15px;
  }
  .appointments {
    padding: 20px 0;
  }
  .get_directions {
    margin-right: 15px;
  }
  .promo_video_mobile img {
    margin-right: 5px;
    position: relative;
    top: 1px;
  }
  .back_btn-right {
    left: 20px;
    top: 12px;
  }

  .appointment-wrapper .left-wrapper {
    border-radius: 1rem 1rem;
  }
  .appointment-wrapper .right-wrapper {
    padding-left: 0px;
    margin-top: 30px;
  }
}
@media (min-width: 768px) {
  .explore_location_mobile,
  .explore_location_mobile1 {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .for_tab_desktop {
    display: none;
  }
  .half-sec,
  .how-works-left,
  .our-services .service-view {
    width: 100%;
  }
  .how-works-info {
    padding: 10px;
  }
  .how-works-info h4 {
    font-size: 14px;
  }
  .how-works-info span {
    width: 50px;
    height: 50px;
    background-size: 42px !important;
    margin-right: 10px;
  }
  .heading {
    font-size: 30px;
  }
  .how-works-right {
    width: 100%;
  }
  .how-works-sec {
    padding: 40px 0 10px;
  }
  .services-sec {
    padding: 40px 0 40px;
  }
  .service_info h4 {
    font-size: 20px;
  }
  .dental-care-group {
    padding-left: 0;
  }
  .dental-care-group {
    margin-top: 40px;
  }
  .dental-care-sec {
    padding: 40px 0;
  }
  .hero-txt h1 span,
  .hero-txt h1 {
    font-size: 30px;
    line-height: 34px;
  }
  .hero-sec {
    background-position: top left !important;
    margin-top: 61px;
  }
  .hero-txt {
    margin-left: 0;
    padding: 22% 0 16%;
  }
  .dental-care-group h3 {
    font-size: 24px;
  }
  .visit-sec {
    padding: 50px 0;
  }
  .visit-col-left {
    width: 100%;
  }
  .visit-col {
    width: 100%;
    margin-left: 0 !important;
  }
  .visit-col.visit-col-right {
    margin-left: 0;
    margin-top: -10px;
  }
  .visit-col-left {
    padding: 35px 20px;
  }
  .left-footer {
    width: 100%;
    text-align: center;
  }
  .right-footer {
    padding: 8px 0 3px;
    display: block;
    margin: 0 auto;
    float: none;
  }
  .social-icons li {
    margin: 10px 15px 0;
  }
  .free-cleaning img {
    margin: 0 auto 10px;
    display: block;
  }
  .heading {
    margin-bottom: 20px;
  }
  .how-works-left {
    margin-top: 0;
  }
  .footer-logo {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .service-view img {
    width: 100%;
  }
  .select-field,
  .offcanvas-left {
    display: none;
  }
  #select-treatment-modal .offcanvas-left {
    display: block !important;
  }
  .offcanvas {
    width: 100%;
    max-width: 100%;
  }
  .offcanvas-right {
    width: 100%;
  }
  .half-field {
    width: 100%;
  }
  .half-field:last-child {
    margin-top: 15px;
  }
  .slot-box {
    width: calc(50% - 10px);
  }
  .offcanvas-right {
    padding: 20px;
  }
  .close-sidemenu {
    top: 10px;
    right: 5px;
  }
  .mobile-pack {
    display: block !important;
  }
  .explore_location_mobile .provider_details_inner,
  .provider_details_mobile .provider_details_inner {
    margin-top: 40px;
  }
  .provider_details_mobile .provider_details_inner h2 {
    text-align: center !important;
  }
  .offcanvas_confirm.video__ .offcanvas_confirm_left {
    display: none;
  }
  .confirm-left,
  .confirm-right {
    width: 100%;
    float: none;
    padding: 0 15px;
  }
  .left-footer {
    display: block;
  }
  .footer-logo {
    justify-content: center;
    margin: auto;
    margin-right: auto !important;
    margin-bottom: 15px;
  }
  .appointments_buttons .btn {
    margin: 0 10px;
  }
  .payment-modal .offcanvas-left {
    width: 100%;
    display: block;
  }
  .offcanvas-right {
    width: 100%;
  }
  .appointment_data {
    padding-top: 10px;
  }
  .offcanvas-right {
    padding: 30px 20px 20px 20px;
  }
  .close-sidemenu {
    width: 22px;
    height: 22px;
    /* background: url(../../images/close.svg) no-repeat; */
    z-index: 99;
    background-color: #fff;
    background-position: center;
  }
  .for_desktop {
    display: none;
  }
  .for_mobile {
    display: inline-block;
  }
  .update_reminder {
    margin-top: 25px;
  }
  .select-slot .item span {
    font-size: 12px !important;
  }
  .popup-main .price-details {
    font-size: 24px;
    margin: 5px 0px;
  }

  .appointment-wrapper .right-wrapper {
    margin-top: 0px;
  }
}
@media (max-width: 575px) {
  .appointments {
    padding: 20px 0;
  }
  .offcanvas_confirm {
    padding: 15px;
  }
  .details_part_section {
    padding: 15px;
  }
  .appointments_buttons {
    display: block;
  }
  .appointments_buttons .btn {
    display: block;
    margin-bottom: 15px;
  }
  .success_confirmed {
    min-width: 91%;
  }
  .appointment_data li {
    min-width: 93px;
    padding: 10px 0px;
    position: relative;
    border-bottom: 1px solid #ccc;
    border-right: none !important;
  }
  .appointment_data li:last-child {
    border-bottom: none;
  }
  ul.appointment_data {
    display: block !important;
    padding-bottom: 0 !important;
  }
  .appointment_data li::after {
    display: none;
  }
  .appointment-title .fa {
    display: block;
  }
  .appointment-title img {
    display: block;
    margin: auto;
  }
  .approve_deny .modal-content .inner_content {
    padding: 41px 13px;
  }
}

.select-slot .owl-carousel {
  display: inline-block !important;
  vertical-align: top;
  max-width: 100%;
  padding: 0 60px;
  border: 1px solid #ccc;
  border-radius: 6px;
  overflow: hidden;
  z-index: unset;
  width: 100%;
}
.select-slot .owl-carousel .owl-carousel {
  padding: 0px 0px;
  margin: 0px 0px;
  border: none;
}
.select-slot .item {
  text-align: center;
  border-right: 1px solid #ccc;
  padding: 4px 0 3px;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.select-slot .item > * {
  width: 100%;
}
.select-slot .owl-item .holiday span,
.select-slot .owl-item .holiday h4 {
  color: #777 !important;
}
.select-slot .owl-item .open span,
.select-slot .owl-item .open h4 {
  color: #fff !important;
}
.select-slot .owl-item .open {
  background: var(--colorPrimary);
}
.select-slot .owl-item .item.holiday {
  background: #f2f2f2;
}
.select-slot .item h4 {
  margin: 0 !important;
  line-height: 17px;
  font-size: 16px;
}
.select-slot .item span {
  font-size: 13px;
  color: var(--colorMuted);
}
.select-slot .owl-nav button {
  position: absolute;
  top: 0;
  left: 0;
  width: 65px;
  background: #ddd !important;
  height: 100%;
  color: var(--colorBody) !important;
  outline: none !important;
}
.select-slot .owl-nav button.owl-next {
  left: unset;
  right: 0;
}
.select-date-error {
  padding: 0 10px 0;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: #000;
}
.select-date-error h3 {
  font-size: 28px;
  font-weight: 500;
}
.select-date > h4 {
  display: inline-block;
  width: unset;
}
.slot-calendar {
  float: right;
  margin-top: 32px;
  color: #000;
  cursor: pointer;
  z-index: 1;
}
.slot-calendar input {
  background: transparent;
  border: none !important;
  line-height: unset !important;
  height: unset !important;
  vertical-align: baseline;
  padding-left: 32px;
  max-width: 120px;
  color: #3578e5;
}
.slot-calendar .fa-calendar {
  z-index: -1;
  margin-right: -30px;
}
.slot-calendar span {
  color: #3578e5;
  font-size: 14px;
  margin-left: 5px;
}
.slot-box.active {
  background: var(--colorSecondary);
  border: none;
  color: #fff;
}
.slot-box:hover {
  background: var(--colorSecondary);
  border: none;
  color: #fff;
}
.appointments {
  background: #f4f4f4;
  padding: 30px 15px 12px 15px;
  border: 1px solid var(--colorGray);
  box-shadow: none;
}
.add_medical_history {
  background-color: var(--colorPrimary);
  padding: 12px 10px;
}
.add_medical_history:hover {
  opacity: 0.8;
  color: #fff;
}
.get_directions {
  width: 100%;
  text-align: center;
  margin-left: 10px;
}
/*sidebar css*/

@media only screen and (max-width: 413px) {
  .select-slot .item h4 {
    font-size: 14px;
  }
  .select-slot .item span {
    font-size: 11px !important;
  }
}
@media only screen and (max-width: 374px) {
  .select-slot .owl-carousel {
    padding-left: 45px;
    padding-right: 45px;
  }
  .select-slot .pick-date .owl-nav button {
    width: 45px;
  }
  #step-tabContent .btn {
    /*padding-left:1rem !important; padding-right:1rem !important;*/
  }
}

.border-right {
  border-right: 1px solid #e7eaec !important;
  margin: 10px;
  padding: 10px;
}

.nav-btn {
  background: inherit !important;
  color: #000000 !important;
  text-align: center;
  outline: none !important;
  border: 0.5px solid #ccc !important;
  background-color: #ccc !important;
}

.open {
  background: var(--colorSecondary);
  color: #fff !important;
}

.date-item {
  text-align: center;
  border: 1px solid #ccc;
  width: 14%;
  color: var(--colorMuted);
}

.date-item h4 {
  color: #000000;
  line-height: 17px;
  font-size: 14px;
}

.open h4 {
  color: #fff !important;
}

.date-section {
  padding: 0 !important;
}

.prev {
  border-radius: 5px 0 0 5px;
}

.next {
  border-radius: 0 5px 5px 0;
}

@media only screen and (max-width: 575px) {
  .prev {
    border-radius: 5px 5px 0 0;
    height: 50px;
  }

  .next {
    border-radius: 0 0 5px 5px;
    height: 50px;
  }
}
