.chat-button {
  width: 60px;
  height: 60px;
  z-index: 3;
}
.chat-button .badge {
  min-width: 17px;
}

.chat-upload-button {
  max-width: 60px;
  position: absolute;
  opacity: 0;
  right: 65px !important;
  z-index: 1;
}

.chat-modal {
  max-width: 300px;
  height: auto;
  position: fixed;
  left: auto;
  right: 1.5rem;
  top: auto;
  bottom: 100px;
  overflow: visible;
  border-radius: 4px;
  transition: all 0.3s ease-out 0s;
}
.chat-modal .modal-dialog {
  margin: var(--valueZero);
  max-width: 100%;
  transform: none !important;
}

.chat-modal[style="display: block;"] {
}
.chat-modal ~ .modal-backdrop {
  /*display:none !important;*/
}

.chat-modal .modal-dialog {
  overflow: hidden;
  border-radius: 4px;
}
.chat-modal .modal-content {
}

.chat-modal .modal-header {
}
.chat-modal .modal-header figure {
}
.chat-modal .modal-header figure img {
  width: 35px;
  min-width: 35px;
  height: 35px;
}
.chat-modal .modal-header .name {
}
.chat-modal .modal-header .status {
  font-size: 12px;
}
.chat-modal .modal-header .status span {
  padding-left: 12px;
}
.chat-modal .modal-header .status span:before {
  content: "";
  background: var(--colorWhite);
  width: 7px;
  height: 7px;
  position: absolute;
  left: 0px;
  top: 7px;
  border-radius: var(--roundedPill);
}

.chat-modal .modal-header .status.online span:before {
  background: var(--colorGreen);
}
.chat-modal .modal-header .status.away span:before {
  background: var(--colorGold);
}
.chat-modal .modal-header .status.offline span:before {
  background: var(--colorWhite);
  opacity: 0.5;
}

.chat-modal .modal-header .fullscreen {
}
.chat-modal .modal-header .chat-close-btn {
  background: none !important;
  color: black;
}

.chat-modal .modal-body {
  padding-bottom: var(--valueZero);
  max-height: 380px;
  overflow-y: auto;
}
.chat-modal .modal-body .chat-wrapper {
}
.chat-modal .modal-body .chat-date {
}
.chat-modal .modal-body .chat-sender {
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin-bottom: 1rem;
}
.chat-modal .modal-body .chat-sender:last-child {
  /*margin-bottom:var(--valueZero);*/
}
.chat-modal .modal-body .chat-sender .msg-time {
  font-size: 13px;
  color: var(--colorMuted);
  display: inline-block;
  vertical-align: top;
  padding-right: calc(35px + 0.425rem);
  width: 100%;
  opacity: 0.75;
}
.chat-modal .modal-body .chat-sender .msg-time small {
}
.chat-modal .modal-body .chat-sender .chat-inner {
  flex: 1; /*display:flex; flex-wrap:wrap; justify-content:flex-end;*/
}
.chat-modal .modal-body .chat-sender .msg-text {
  background: var(--colorPrimary);
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  line-height: 1.2;
  display: table;
  padding: 0.275rem 0.5rem 0.475rem 0.5rem;
  margin-left: auto;
  margin-bottom: 0.5rem;
  width: auto;
  max-width: 90%; /*max-width:calc(100% - 60px);*/
  border-radius: 0.75rem;
}
.chat-modal .modal-body .chat-sender .msg-text:last-child {
  margin-bottom: var(--valueZero);
}
.chat-modal .modal-body .chat-sender .msg-text a {
  color: inherit;
}
.chat-modal .modal-body .chat-sender .msg-text img {
}
.chat-modal .modal-body .chat-sender figure {
  margin: var(--valueZero);
  margin-left: 0.425rem;
  width: 35px;
  min-width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: var(--roundedPill);
}
.chat-modal .modal-body .chat-sender figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chat-modal .modal-body .chat-replyer {
  text-align: left;
  flex-direction: row;
}
.chat-modal .modal-body .chat-replyer .chat-inner {
  justify-content: flex-start;
}
.chat-modal .modal-body .chat-replyer .msg-text {
  background: var(--colorSecondary);
  margin-left: var(--valueZero);
  margin-right: auto;
}
.chat-modal .modal-body .chat-replyer figure {
  margin-left: var(--valueZero);
  margin-right: 0.425rem;
}
.chat-modal .modal-body .chat-replyer .msg-time {
  padding-left: calc(35px + 0.425rem);
  padding-right: var(--valueZero);
}

.chat-modal .modal-footer {
}
.chat-modal .modal-footer .form-control {
}
.chat-modal .modal-footer input[type="text"] {
  font: inherit;
  color: inherit;
  padding: 0.275rem 0rem 0.45rem 0.5rem;
}
.chat-modal .modal-footer .link-attachment {
}
.chat-modal .modal-footer .link-image {
}
.chat-modal .modal-footer .msg-send-btn {
  width: 34px;
  min-width: 34px;
  height: 34px;
  z-index: 500;
}
.chat-modal .modal-footer .msg-send-btn i {
}

/* body.chat-fullscreen {}
body.chat-fullscreen .chat-modal { width:100%; max-width:100%; height:100%; right:0px; bottom:0px; border-radius:0px; }
body.chat-fullscreen .chat-modal .modal-dialog { border-radius:0px; }
body.chat-fullscreen .chat-modal .modal-body { height:100vh; max-height:calc(100vh - 115px); }
body.chat-fullscreen .chat-modal .modal-body .chat-sender .msg-text { max-width:80%; }
body.chat-fullscreen .chat-modal .modal-header .fullscreen i:before { content:"\F1B8"; } */

.chat-fullscreen {
  opacity: 1 !important;
}
.chat-fullscreen.chat-modal {
  width: 100%;
  max-width: 100%;
  height: 100%;
  right: 0px;
  bottom: 0px;
  border-radius: 0px;
}
.chat-fullscreen.chat-modal .modal-dialog {
  border-radius: 0px;
}
.chat-fullscreen.chat-modal .modal-body {
  height: 100vh;
  max-height: calc(100vh - 115px);
}
.chat-fullscreen.chat-modal .modal-body .chat-sender .msg-text {
  max-width: 80%;
}
.chat-fullscreen.chat-modal .modal-header .fullscreen i:before {
  content: "\F1B8";
}

.chat-modal[aria-modal="true"] {
  opacity: 1 !important;
}

#anonymous-cici-chat .textbox-control {
  width: 100%;
}

#anonymous-cici-chat .chatbox {
  max-width: 100% !important;
}

#anonymous-cici-chat .chat-msg {
  font-size: 13px !important;
}

#anonymous-cici-chat .modal-footer {
  padding: 0px !important;
}

#anonymous-cici-chat {
  background-color: transparent;
  height: 100vh;
}

#anonymous-cici-chat figure {
  margin-left: 30px !important;
}

#anonymous-cici-chat .cicichattranslate {
  margin: 10px;
}

#anonymous-cici-chat .chat-button:hover {
  width: 65px;
  height: 65px;
}

.no-background {
  top: 0px !important;
  background: none !important;
}

#anonymous-cici-chat #chat-wrapper-cici {
  max-height: 347px !important;
  overflow-y: auto;
  padding: var(--valueZero) !important;
}

.chat-toast {
  width: 300px;
  height: auto;
  position: fixed;
  left: auto;
  right: 1.5rem;
  top: auto;
  bottom: 100px;
  z-index: 10;
}
.chat-toast .toast {
  background-color: var(--colorWhite);
  border: 1px solid rgba(0, 0, 0, 0.06) !important;
  border-bottom: none !important;
  border-radius: 4px;
  position: relative;
}
.chat-toast .toast .toast-header .btn-close {
  font-size: 10px;
}
.chat-toast .toast-body {
}
.chat-toast .toast-body figure {
  width: 35px;
  min-width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: var(--roundedPill);
}
.chat-toast .toast-body figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .chat-button {
    width: 40px;
    height: 40px;
  }
  .chat-modal {
    max-width: 270px;
    bottom: 80px;
  }
  .chat-toast {
    width: 270px;
    bottom: 80px;
  }
}

.send-btn-container {
  margin: 0;
  z-index: 500;
}

.chat-profile-initial {
  width: 35px;
  min-width: 35px;
  height: 35px;
  background-color: #0a1d62;
  color: white;
}
