/* Prescription and Notes Print Page CSS*/
@import url("https://fonts.googleapis.com/css?family=Dancing+Script");
table tr:last-child td {
  border-bottom: none;
}
@media print {
  .noprint {
    display: none;
  }

  .dental-condition {
    margin-bottom: 0 !important;
  }

  div.leadinModal {
    display: none !important;
  }
  .whiteness_num {
    font-size: 44px;
    padding: 0px 14px 10px;
    border-radius: 12px;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .whiteness-score-header {
    text-align: center;
  }
  .whiteness-score-div {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
@media all and (max-width: 991px) {
  table.presc-print-table {
  }
  table.presc-print-table thead {
    display: block;
  }
  table.presc-print-table tr {
    display: table;
  }
  table.presc-print-table tr th,
  table.presc-print-table tr th:first-child,
  table.presc-print-table tr td:first-child {
    padding-top: 0.5rem !important;
  }
  table.presc-print-table tr td:before {
    display: none;
  }
  table.presc-print-table tr > *[data-label="Description"] {
    /*min-width:85px; max-width:85px;*/
  }
  .gth-signature p {
    background-color: #e5e6e7 !important;
    border-color: #e5e6e7 !important;
    padding: 15px 30px !important;
    height: auto;
    font-size: 42px !important;
    font-family: "Dancing Script" !important;
    text-align: center !important;
    text-decoration: underline !important;
  }
}
@media all and (max-width: 767px) {
  .presc-print-table td {
    display: block;
    text-align: left;
    border: 0;
    width: 100%;
    padding: 5px 10px 5px 120px !important;
    position: relative;
  }
  .presc-print-table thead {
    display: none;
  }
  .presc-print-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 0;
  }
  .table thead {
    display: none;
  }
  .table tr {
    border-bottom: 1px solid #ccc;
  }
  .table tr:last-child {
    border-bottom: none;
  }
  .table td {
    display: block;
    text-align: left;
    border: 0;
    width: 100%;
    padding: 5px 10px !important;
    position: relative;
  }
  .table tr td:first-child {
    padding-top: 1rem !important;
  }
  .table tr td:last-child {
    padding-bottom: 1rem !important;
  }
  .table td:before {
    content: attr(data-label);
    font-weight: 700;
    display: table;
    margin-top: 5px;
    margin-bottom: 3px;
    width: 100%;
    position: static;
  }
  .gth-signature p {
    background-color: #e5e6e7 !important;
    border-color: #e5e6e7 !important;
    padding: 15px 30px !important;
    height: auto;
    font-size: 42px !important;
    font-family: "Dancing Script" !important;
    text-align: center !important;
    text-decoration: underline;
    width: 30% !important;
  }
}

@media (max-width: 575px) {
  .patient-detail thead th {
    display: block;
    padding: 5px 0px !important;
  }
}

.gth-signature {
  background-color: #e5e6e7 !important;
  border-color: #e5e6e7 !important;
  padding: 15px 30px !important;
  height: auto;
  font-size: 42px !important;
  font-family: "Dancing Script" !important;
  text-align: center !important;
  text-decoration: underline;
  display: inline-block;
  text-underline-position: under !important;
}

@media print {
  #prescription-body::-webkit-scrollbar {
    display: none;
  }

  #prescription-body {
    padding: 20px 5px !important;
  }

  #prescription-body .presc-print-table {
    font-size: 11px !important;
  }

  #prescription-body .presc-print-table thead tr th {
    padding: 0 0.1rem !important;
  }

  #prescription-body .presc-print-table tbody tr td {
    padding: 0.5rem 0.1rem !important;
  }

  #prescription-body .gth-signature {
    background-color: #e5e6e7 !important;
    -webkit-print-color-adjust: exact;
    border-color: #e5e6e7 !important;
    padding: 15px 30px !important;
    height: auto;
    font-size: 42px !important;
    font-family: "Dancing Script" !important;
    text-align: center !important;
    text-decoration: underline;
    display: inline-block;
  }

  #note-body .gth-signature {
    -webkit-print-color-adjust: exact;
  }

  #form-body {
    -webkit-print-color-adjust: exact;
  }

  #chat-module {
    display: none;
  }
}

.prescription-print-note-title {
  font-weight: bold !important;
  color: black;
  text-align: "left";
  font-size: 12px !important;
}

.prescription-print-note-details {
  text-align: "left";
  font-size: 12px !important;
}

.presc-print-table {
  font-size: 12px !important;
}

table.presc-print-table tr th,
table.presc-print-table tr td {
  display: table-cell;
  padding: 0.5rem 0.1rem !important;
  width: auto;
  min-width: 70px;
  max-width: 70px;
}

table.presc-print-table tr td {
  word-break: break-all;
}

.note-signature-text {
  font-size: 16px;
  padding: 30px 0 10px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.page-break-before-auto {
  break-before: auto;
}

.visit-history-summary-table-title {
  font-weight: bold !important;
  color: black;
  font-size: 12px !important;
}

.visit-history-summary-td-title {
  background: #eee;
  padding: 0.5rem 0.5rem;
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.visit-history-summary-margin {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
