.landing-page-btn{
    color: red;
}

.header__main-bar .header__container {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.header__container {
    max-width: 1300px;
    margin: 0 auto;
}

@media (min-width: 768px) {
    footer .group-wrapper {
        align-items: center !important;
        display: flex !important;
        float: none !important;
    }
}

.container-fluid {
    width: 100%;
    margin-bottom: 10px;
}

.container-fluid .footer {
    min-height: 200px;
}

.ddd-registration {
    font-family: Gotham-Light, sans-serif;
    background: #fff;
    color: #333;
    font-family: Arial, Sans-Serif;
    font-size: 13px;
    line-height: 1.5;
    overflow-x: hidden;
    font-style: normal;
    font-weight: 300;
}


@media (min-width: 1200px) {
    .ddd-registration .header {
        position: fixed;
        width: 100%;
        z-index: 99999;
    }
}

.ddd-registration .header {
    border-bottom: 1px solid #dcdfe6;
    box-shadow: none;
}

@media (max-width: 768px) {
    .ddd-registration .header__main-bar {
        padding: 12px !important;
    }

    .ddd-registration h2 {
        font-size: 28px !important;
    }
}

.header__logo__container {
    align-items: center;
    display: flex;
}

@media screen and (min-width: 768px) {
    .header__menu {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        align-items: flex-end;
    }
}

.header__menu {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    align-items: center;
    text-align: left !important;
    right: 5px;
}

@media screen and (min-width: 768px) {
    .header__navigation-toggle {
        display: none;
    }
}

body .header__mobile-menu-icon, body .header__mobile-menu-icon:after, body .header__mobile-menu-icon:before {
    background-color: #7d4099;
}

#nav-toggle, .submenu-toggle {
    display: none;
}

.ddd-registration [type='checkbox'], .ddd-registration [type='radio'] {
    box-sizing: border-box;
    padding: 0;
}

.ddd-registration input {
    overflow: visible;
}

.ddd-registration button, .ddd-registration input, .ddd-registration optgroup, .ddd-registration select, .ddd-registration textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

@media screen and (min-width: 768px) {
    .header__navigation {
        background-color: transparent;
    }
}

.header__navigation {
    background-color: #fff;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
    display: block;
}

.row-fluid-wrapper:last-child .hs-menu-wrapper.hs-menu-flow-horizontal>ul {
    margin-bottom: 0;
}

.header .hs-menu-wrapper.hs-menu-flow-horizontal>ul {
    align-items: center;
    display: inline-block;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.hs-menu-wrapper.hs-menu-flow-horizontal ul {
    list-style: none;
    margin: 0;
}

.hs-menu-wrapper ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.hs-menu-wrapper.hs-menu-flow-horizontal>ul li.hs-menu-depth-1 {
    float: left;
    margin: 0;
}

.hs-menu-wrapper.hs-menu-flow-horizontal>ul li {
    padding: 5px 20px;
}

.ddd-registration ul li, .ddd-registration ol li {
    line-height: 1.5rem;
    margin: 0.5rem;
}

.ddd-registration li, .ddd-registration p {
    color: #585657;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.78;
    margin: 0 0 8px;
}

.ddd-registration li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
}

@media (min-width: 1200px) {
    .header__menu .header__navigation .submenu.level-1>.menu-item:not(:last-child) .menu-link, .header__menu .header__navigation .submenu.level-1>.menu-item:not(:last-child)>a, header .hs-menu-wrapper.hs-menu-flow-horizontal>ul li:not(:last-child) a {
        color: #0d2547;
    }
}

header .hs-menu-wrapper.hs-menu-flow-horizontal>ul li:not(:last-child) a {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0;
    padding: 10px 0;
    text-decoration: none;
    text-transform: none;
}

.header__menu .header__navigation .submenu.level-1>.menu-item:not(:last-child) .menu-link, .header__menu .header__navigation .submenu.level-1>.menu-item:not(:last-child)>a, header .hs-menu-wrapper.hs-menu-flow-horizontal>ul li:not(:last-child) a {
    color: #615da8;
}

.hs-menu-wrapper.hs-menu-flow-horizontal>ul li a {
    color: #fff;
    font-size: 20px;
    max-width: none;
    padding: 0 20px;
    padding-bottom: 10px;
    text-overflow: clip;
}

.ddd-registration a:visited {
    font-weight: 300;
    text-decoration: underline;
    font-family: Inter, sans-serif;
    font-style: normal;
    color: #23EDC0;
    font-size: 16px;
    color: #14deb1;
}

.ddd-registration a:link, .ddd-registration a:visited, .ddd-registration a:active {
    color: #209fd9;
    text-decoration: none;
}

.hs-menu-item a {
    display: block;
    padding: 0.5rem 1rem;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
}

.header__menu .header__navigation nav>ul>li.menu-item:last-child a.menu-link:hover, header .hs-menu-wrapper.hs-menu-flow-horizontal>ul li:last-child a, header .hs-menu-wrapper.hs-menu-flow-horizontal>ul li:last-child a:hover, nav.navigation-primary>ul>li.menu-item:last-child a {
    border-radius: 0;
    -moz-box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    cursor: pointer;
    display: inline-block;
    font-family: Gotham-Medium, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: normal !important;
    padding: 11.7px 21px;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    transition: all .4s ease;
    -moz-transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    background-color: #3fbbeb;
    border: 1.5px solid #3fbbeb;
    border-radius: 25px;
    color: #fff;
    letter-spacing: .4px;
    outline: 0;
    text-transform: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    width: auto;
}

.dnd-section {
    padding: 50px 0px;
}

.dnd-section > .row-fluid {
    max-width: 1300px;
}

.dnd-section > .row-fluid {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 1280px) {
    .row-fluid {
        width: 100%;
    }
}

.row-fluid {
    width: 100%;
}

@media (min-width: 1280px) {
    .row-fluid:before, .row-fluid:after {
        display: table;
        content: "";
    }
}

.row-fluid:before, .row-fluid:after {
    display: table;
    content: "";
}

@media (min-width: 1280px) {
    .row-fluid:after {
        clear: both;
    }
}

.row-fluid:after {
    clear: both;
}

body .row-fluid [class*=span] {
    min-height: 0;
}

.row-fluid [class*="span"]:first-child {
    margin-left: 0;
}

.dnd-section .widget-type-cell {
    padding-left: 0;
    padding-right: 0;
}

.row-fluid [class*="span"] {
    display: block;
    float: left;
    width: 100%;
    min-height: 28px;
    margin-left: 2.564102564%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

@media(min-width: 768px) {
    .cb-cards-cm {
        display:flex;
        gap: 50px
    }

    .cb-cards-cm__next-card {
        flex-basis: 50%;
        width: 50%
    }

    .cb-cards-cm__next-card-img img {
        max-height: 50vh
    }
}

@media (max-width: 1200px) {
    .cb-cards-cm {
        padding:0 32px
    }
}

@media(max-width: 767px) {
    .cb-cards-cm__next-card {
        margin-bottom:32px
    }
    .cb-cards-cm {
        padding:20px 15px !important;
    }
}

.cb-cards-cm__next-card-img img {
    margin: 0 auto;
    text-align: center
}

.cb-cards-cm__next-card {
    border: 1px solid #000;
    padding: 50px;
    text-align: center
}

.cb-cards-cm__next-card-intro {
    margin-bottom: 36px
}

.dnd_area_main_banner-row-2-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.dnd_area_main_banner-row-2-force-full-width-section > .row-fluid {
    max-width: none !important;
}

.dnd-section > .row-fluid {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

*, :after, :before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.row-fluid:before, .row-fluid:after {
    display: table;
    content: "";
}

@media (min-width: 1280px) {
    .row-fluid:before, .row-fluid:after {
        display: table;
        content: "";
    }
}

.dnd-section .widget-type-cell {
    padding-left: 0;
    padding-right: 0;
}

@media (min-width: 1280px) {
    .row-fluid .span12 {
        width: 100%;
    }
}

@media (min-width: 1280px) {
    .row-fluid [class*="span"] {
        display: block;
        float: left;
        width: 100%;
        min-height: 28px;
        margin-left: 2.564102564%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
    }
}

@media (max-width: 767px) {
    .row-fluid {
        width: 100%;
    }

    .row-fluid [class*="span"] {
        display: block;
        float: none;
        width: auto;
        margin-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .row-fluid {
        width: 100%;
    }

    .row-fluid:before, .row-fluid:after {
        display: table;
        content: "";
    }

    .row-fluid:after {
        clear: both;
    }

    .row-fluid [class*="span"] {
        display: block;
        float: left;
        width: 100%;
        min-height: 28px;
        margin-left: 2.762430939%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
    }

    .row-fluid [class*="span"]:first-child {
        margin-left: 0;
    }

    .row-fluid .span12 {
        width: 99.999999993%;
    }

    .row-fluid .span11 {
        width: 91.436464082%;
    }

    .row-fluid .span10 {
        width: 82.87292817100001%;
    }

    .row-fluid .span9 {
        width: 74.30939226%;
    }

    .row-fluid .span8 {
        width: 65.74585634900001%;
    }

    .row-fluid .span7 {
        width: 57.182320438000005%;
    }

    .row-fluid .span6 {
        width: 48.618784527%;
    }

    .row-fluid .span5 {
        width: 40.055248616%;
    }

    .row-fluid .span4 {
        width: 31.491712705%;
    }

    .row-fluid .span3 {
        width: 22.928176794%;
    }

    .row-fluid .span2 {
        width: 14.364640883%;
    }

    .row-fluid .span1 {
        width: 5.801104972%;
    }
}

@media screen and (max-width: 768px) {
    .mobile-col {
        flex-direction: column;
    }
}

body .ddd-registration {
  background-color: #fff;
  color: #3D3E40;
  font-family: "Muli", "sans-serif";
  font-weight: 300;
  line-height: 1.5;
}

#hs_cos_wrapper_widget_1709595942016 .cb-cards-cm__next-card.item-1 .cb-cards-cm__next-card-intro * {
    color: #3fbbeb;
}

.ddd-registration blockquote {
    margin: 0;
    padding: 0;
}

.ddd-registration h2 {
    font-family: Gotham-Light, sans-serif;
    font-size: 28px;
    font-style: normal;
    text-decoration: none;
    color: #0D2547;
    font-size: 36px;
    text-transform: none;
    letter-spacing: 0px;
    line-height: 1.5em;
    -webkit-margin-before: 0;
}

.ddd-registration h2, .ddd-registration label {
    font-family: Calibri-Bold, sans-serif;
}

.ddd-registration h2{
    word-break: break-word;
}

#hs_cos_wrapper_widget_1709595942016 .cb-cards-cm__next-card.item-2 .cb-cards-cm__next-card-intro * {
    color: #80489b;
}

#hs_cos_wrapper_widget_1709595942016 .cb-cards-cm__next-card.item-1 .button-w a {
    color: #fff;
    background-color: #3fbbeb;
}

a.cta_button, body .hs-button.primary, .ddd-registration input[type=button], .ddd-registration input[type=submit], span.cta_button {
    -moz-box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    cursor: pointer;
    display: inline-block;
    font-family: Gotham-Medium, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: normal !important;
    padding: 11.7px 21px;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    transition: all .4s ease;
    -moz-transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    background-color: #e4c821;
    border: 1.5px solid #e4c821;
    border-radius: 50px !important;
    color: #fff;
    letter-spacing: .4px;
    outline: 0;
    -moz-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    width: auto;
}


.ddd-registration a:visited {
    font-weight: 300;
    text-decoration: underline;
    font-family: Inter, sans-serif;
    font-style: normal;
    color: #23EDC0;
    color: #14deb1;
}

a.cta_button {
    -moz-box-sizing: content-box !important;
    -webkit-box-sizing: content-box !important;
    box-sizing: content-box !important;
    vertical-align: middle;
}

#hs_cos_wrapper_widget_1709595942016 .cb-cards-cm__next-card.item-2 .button-w a {
    color: #fff;
    background-color: #80489b;
}

.ddd-registration a.cta_button:hover, .ddd-registration form input[type=submit]:focus, .ddd-registration form input[type=submit]:hover, .ddd-registration span.cta_button:hover {
    background-color: #fcc36c;
    border-color: #fcc36c;
    opacity: .8;
}

@media (min-width: 1200px) {
    .footer__top-section .footer__info  {
    text-align: left;
    }
}

.ddd-registration header {
    background-color: #fff;
    border-bottom: 1px solid #dcdfe6;
    left: 0;
    position: fixed;
    top: 0;
    transition: all .5s linear;
    width: 100%;
    z-index: 9999;
}

.header__main-bar {
    padding: 0.75rem 0;
    position: relative;
}

#nav-toggle, .submenu-toggle {
    display: none;
}

.ddd-registration [type='checkbox'], .ddd-registration [type='radio'] {
    box-sizing: border-box;
    padding: 0;
}

.ddd-registration button, .ddd-registration input {
    overflow: visible;
}

.ddd-registration button, .ddd-registration input, .ddd-registration optgroup, .ddd-registration select, .ddd-registration textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

@media screen and (max-width: 767px) {
    .header__navigation {
        display:none;
        position: absolute;
        right: 0;
        top: 100%;
        width: 100%;
        padding: 0 30px;
    }

    #hs_cos_wrapper_widget_1709595942016 .cb-cards-cm__next-card {
        border: 0;
        padding: 20px 15px;
        text-align: left;
        border-radius: 16px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }

    #hs_cos_wrapper_widget_1709595942016 .cb-cards-cm__next-card-img {
        display: none;
    }

    .dnd-section {
        padding: 50px 0px;
    }

    .cb-cards-cm__next-card {
        margin-bottom: 32px;
    }

    .header__navigation-toggle {
        cursor: pointer;
        display: block
    }

    .header__mobile-menu-icon,.header__mobile-menu-icon:after,.header__mobile-menu-icon:before {
        background-color: #4d4d4d !important;
        border-radius: 10px;
        display: inline-block;
        height: 4px;
        width: 24px
    }

    .header__mobile-menu-icon {
        position: relative;
        top: -6px
    }

    .header__mobile-menu-icon:before {
        top: -8px
    }

    .header__mobile-menu-icon:after {
        top: 8px
    }

    .header__mobile-menu-icon:after,.header__mobile-menu-icon:before {
        content: "";
        position: absolute
    }

    #nav-toggle:checked~.header__navigation {
        display: block
    }

    #nav-toggle:checked+.header__navigation-toggle .header__mobile-menu-icon {
        margin: 0 auto;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg)
    }

    #nav-toggle:checked~.header__navigation {
        box-shadow: 0 15px 16px rgba(0, 0, 0, .1); 
    }

    #nav-toggle:checked~.header__navigation {
        background-color: #fff;
        display: block;
        z-index: 9;
    }

    #nav-toggle:checked+.header__navigation-toggle .header__mobile-menu-icon:before {
        content: none
    }

    #nav-toggle:checked+.header__navigation-toggle .header__mobile-menu-icon:after {
        top: 0;
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg)
    }

    .menu-arrow-icon,.menu-arrow-icon:after {
        background-color: #000;
        border-radius: 10px;
        display: block;
        height: 4px;
        width: 20px
    }

    .menu-arrow-icon {
        margin: 0 auto;
        position: relative;
        top: 8px
    }

    .menu-arrow-icon:after {
        content: "";
        position: absolute;
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg)
    }

    .ddd-registration input[type=checkbox]:checked~.menu-arrow .menu-arrow-bg {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: -webkit-transform .4s;
        transition: -webkit-transform .4s;
        -o-transition: transform .4s;
        transition: transform .4s;
        transition: transform .4s,-webkit-transform .4s
    }

    .submenu .menu-arrow,.submenu.level-1>.has-submenu>.menu-arrow,.submenu>.has-submenu>.menu-arrow {
        border: none;
        cursor: pointer;
        padding: 13px 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 30%
    }

    .submenu .menu-arrow-bg,.submenu.level-1>.has-submenu>.menu-arrow-bg,.submenu>.has-submenu>.menu-arrow-bg {
        display: block;
        height: 20px;
        margin-left: auto;
        margin-right: 30px;
        -webkit-transition: -webkit-transform .4s;
        transition: -webkit-transform .4s;
        -o-transition: transform .4s;
        transition: transform .4s;
        transition: transform .4s,-webkit-transform .4s;
        width: 20px
    }

    .ddd-registration .footer {
        width: 100%;
    }
}

.ddd-registration .footer {
    background-color: rgba(238, 238, 238, 1.0);
    border-top: 1px none #fcc36c;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 300;
    text-decoration: none;
    font-size: 16px;
    color: #efefef;
    padding: 50px 0;
}

.ddd-registration .footer, .ddd-registration .footer p {
    font-size: 13px;
    line-height: 1.5em;
}

.ddd-registration .footer .copyright-wrapper.social.span8 {
    margin-top: 15px;
    text-align: center;
}

@media (max-width: 1250px) {
    header .hs-menu-wrapper.hs-menu-flow-horizontal>ul li:not(:last-child) a {
        font-size: 14px;
    }
}

@media (max-width: 568px) {
    .hs-menu-wrapper, .hs-menu-wrapper * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        width: 100%;
    }

    .hs-menu-wrapper.hs-menu-flow-horizontal ul {
        list-style: none;
        margin: 0;
        display: block;
    }

    .hs-menu-wrapper.hs-menu-flow-horizontal > ul {
        display: block;
    }

    .hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-menu-depth-1 {
        float: none;
    }

    .hs-menu-wrapper.hs-menu-flow-horizontal > ul li a,
    .hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-item-has-children ul.hs-menu-children-wrapper li a,
    .hs-menu-wrapper.hs-menu-flow-horizontal.hs-menu-show-active-branch > ul li.hs-item-has-children.active-branch > ul.hs-menu-children-wrapper > li a {
        display: block;
        max-width: 100%;
        width: 100%;
    }
}

.hs-menu-item :hover {
    background-color: #fff;
    opacity: 0.8;
    padding: 7px 10px;
    visibility: visible;
    cursor: pointer;
}

.ddd-registration .header__logo__container>div img, .footer__logo>div img {
    max-height: 41px;
    max-width: 200px;
}

@media (min-width: 768px) {
    .ddd-registration .header__logo img {
        max-width: 300px;
    }
}

.footer__container {
    margin: 0 auto;
    max-width: 1340px;
    padding: 2.8rem 2.8rem 0 2.8rem;
}

@media (min-width: 1200px) {
    .ddd-registration .text-center {
        text-align: left !important;
    }

    .ddd-registration .footer .text-center {
        margin-left: 10px;
    }
}
